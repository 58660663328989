// ///////////////////////////////////////////////////////////////////////////////
// Composant principal pour le moteur de maquette/pdv 360
// /!\ Se compose de multiples sous composant et hook pour fonctionner
// /!\ Le player ne s'occupe pas de naviger d'un etage a un autre, il ne fait que la rotation
// et les interactions 3D pour une suite d'image
// ///////////////////////////////////////////////////////////////////////////////
// Creation 29/05/2022                  CGR
// Reintepretation D3D  18/10/2022      CGR
// ///////////////////////////////////////////////////////////////////////////////


import React, { useState, useRef, useEffect, useContext } from "react";
import { Canvas, useStore } from "@react-three/fiber";
import ThreeJsPlayer from './player-3D';

import useResolution from './hooks/useResolution';
import { navigationContext } from "../../contexts";
import { useParams } from "react-router-dom";
import SvgIcon from '../svg/svg';
import PlayerLegend from './playerLegend';
import PlayerRotate from './playerRotate';

import LegendLine from '../../components/caption/legendLine/legendLine'



const Player = ({
    programme,
    navUrl,
    type = 'maquette',
    floor = null,
    alreadyOveredLot = null,
    setAlreadyOveredLot = null,
    filterOne = undefined,
    start = 0,
    forcedBlock = null,
    numFrames = 90,
    resolutions = [1080, 720, 450, 156],
    autoPlayDuration = 10,
    autoPlaySpeed = 0,
    loadHdForFrame = () => { },
    onlyImages = false,
    boussole
}) => {

    // //////////////////////////////
    // Properties


    const playerRef = useRef(null); // Ref vers player
    const canvasRef = useRef(null); // Ref vers canvas 2D
    const canvas3DRef = useRef(null);
    const {
        d3dDatas,
        searchResult,
        ficheProjet,
        setFicheProjet,
        ficheProjetRef,
        block,
        setBlock,
        currentMqtLvl0,
        setCurrentMqtLvl0,
        currentMqtLvl1,
        setCurrentMqtLvl1,
        currentLvl2,
        preloaded,
        imgsMemory,
        currentRotator,
        medias,
        projects
    } = useContext(navigationContext);

    const frames = useRef(numFrames);
    const standardRatio = 1920 / 1080; // ratio standard
    const { filtre } = useParams();
    // const [frame, convertedFrame, rotate, setFrame] = useRotate(start < numFrames ? start : 0, numFrames);
    const [frame, setFrame] = useState(0);
    const [zoom, setZoom] = useState(1); // Zoom sur le player
    const [focale, setFocale] = useState(40); // Focale du player
    // const [speed, inertia, isMoving] = useMouseTracker(playerRef, numFrames, 400);
    const [resolution, setResolution, callLoadHd] = useResolution(preloaded, resolutions[0], resolutions.slice(1, resolutions.length));
    const [imgStyle, setImgStyle] = useState('fromHeight'); // alignement du media
    const [puces, setPuces] = useState([]); // liste des puces
    const [indicators, setIndicators] = useState([]);
    const [playerToolTip, setPlayerToolTip] = useState(null); // reference au toolTip de lot
    const [player3D, setPlayer3D] = useState(null); // Reference au component Player3D
    const [render, setRender] = useState(null); // image a rendre
    const [offsetWidth, setOffsetWidth] = useState(0);
    const [offsetHeight, setOffsetHeight] = useState(0);
    const [sizeWidth, setSizeWidth] = useState(0);
    const [sizeHeight, setSizeHeight] = useState(0);
    const [useBlock, setUseBlock] = useState(forcedBlock ? forcedBlock : block)
    const isDraggingOnMouse = useRef(false);

    // New
    const [currentSpeed, setCurrentSpeed] = useState(0);
    const [startSpeed, setStartSpeed] = useState(0);
    const [timeToStop, setTimeToStop] = useState(0);
    let clientX = 0;
    const mouseX = useRef(0);
    let accStartTime = null;
    let accStartX = null;
    let startTime = -1;
    let rotateResistance = .8;

    let clientY = 0;
    let accStartDir = false;
    let direction = true;
    const [directionSt, setDirectionSt] = useState("droite");
    const [accStartY, setAccStartY] = useState(null)

    let targetRotation = null;
    const [targetRotationSt, setTargetRotationSt] = useState(0);
    const mouseXOnMouseDown = useRef(0);
    const targetRotationOnMouseDown = useRef(frame);
    const [rotation, setRotation] = useState(0);

    // //////////////////////////////
    // Fonctions

    const getPlayerSizes = (canvas) => { // Fonction pour obtenir les dimensions ecrans du player

        const player = playerRef.current;
        const windowRatio = player.clientWidth / player.clientHeight;

        if (windowRatio < standardRatio) {
            setImgStyle('fromHeight')
            canvas.height = player.offsetHeight;
            canvas.width = canvas.height * standardRatio;
        } else {
            setImgStyle('fromWidth')
            canvas.width = player.offsetWidth;
            canvas.height = canvas.width / standardRatio;
        }

        const offsetX = -(canvas.width * zoom - player.offsetWidth) / 2;
        const offsetY = -(canvas.height * zoom - player.offsetHeight) / 2;

        return [offsetX, offsetY, canvas.width, canvas.height]
    }

    const setPlayer3DSizes = (w, h, oW, oH) => {

        const playerZone = document.getElementById("playerZone");
        playerZone.style.transform = `translate(${oW}px, ${oH}px)`;
        const canvas3D = document.getElementById("player-3D");
        if (canvas3D) {
            canvas3D.childNodes[0].style.width = `${w}px`;
            canvas3D.childNodes[0].style.height = `${h}px`;
        }
    }

    const update3D = () => { // Fonction pour la mise a jour du player3D
        // Mise en place du three

        if (type === "maquette") {

            setPlayer3D(
                <Canvas id="player-3D" ref={canvas3DRef} camera={{ fov: 75, position: [0, 0, 70] }}>
                    <ThreeJsPlayer
                        d3dDatas={d3dDatas}
                        programme={programme}
                        frame={frame}
                        block={block}
                        setBlock={setBlock}
                        floor={floor}
                        focale={focale}
                        setSurvol={setPlayerToolTip}
                        medias={medias}
                        puces={puces}
                        setPuces={setPuces}
                        setIndicators={currentLvl2 === "" ? () => { setIndicators(null) } : setIndicators}
                        alreadyOveredLot={alreadyOveredLot}
                        filterOne={filterOne}
                        zoom={zoom}
                        navInfo={{
                            current0: currentMqtLvl0,
                            current1: currentMqtLvl1,
                            setCurrentLvl0: setCurrentMqtLvl0,
                            setCurrentLvl1: setCurrentMqtLvl1,
                        }}
                        searchResult={searchResult}
                        ficheProjetRef={ficheProjetRef}
                        ficheProjet={ficheProjet}
                        setFicheProjet={setFicheProjet}
                        isMoving={currentSpeed !== 0}
                        projects={projects}

                    // setExtActiv = {addExtPageFromPoi}
                    />
                </Canvas>
            );
        }
    }

    const convertFrame = (frame) => { // Fonction pour obtenir le numero de frame en str
        return frame.toLocaleString('en-US', { minimumIntegerDigits: 3, useGrouping: false })
    }

    // //////////////////////////////
    // Effects
    // Effects pour la gestion de la rotation et de la resolution
    useEffect(() => { // recuperations des infos du Json

        if (programme !== undefined) {

            /// Lets - go 
            playerRef.current.addEventListener("touchmove", (ev) => {

                if (ev.target.className.includes("navigationChoice")) {
                    return;
                }

                if (frames.current > 1) {
                    const x = ev.touches[0].screenX
                    const y = ev.touches[0].screenY

                    clientX = x;
                    clientY = y;

                    var mouseXTmp = x - window.innerWidth / 2;

                    direction = 'gauche';
                    setDirectionSt('gauche')
                    if ((mouseX.current - mouseXTmp) < 0) {
                        direction = 'droite';
                        setDirectionSt('droite')
                    }
                    if (direction != accStartDir) {
                        accStartDir = direction;
                        accStartTime = new Date().getTime();
                        accStartX = clientX;
                    }
                    mouseX.current = mouseXTmp;

                    // calcule la rotation en fonction de la distance parcourue
                    var largeurCanvas = canvasRef.current.width;
                    var distance = (mouseX.current - mouseXOnMouseDown.current) / .3; // correction de la vitesse
                    var angle = distance * 180 / largeurCanvas;
                    targetRotation = (targetRotationOnMouseDown.current + angle) % 360;
                    targetRotation = Math.floor(targetRotation);
                    if (targetRotation > 360) {
                        targetRotation = targetRotation - 360;
                    }
                    if (targetRotation < 0) {
                        targetRotation = targetRotation + 360;
                    }
                    setTargetRotationSt(targetRotation);
                    setRotation(targetRotation);

                    var time = new Date().getTime(); // temps en ms
                    var tempsEcoule = time - accStartTime; // temps écoulé en ms
                    var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
                    var rayonRotation = canvasRef.current.width;
                    var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                    var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
                    // fixe un maximum
                    if (vitesseAngulaire > 8) {
                        vitesseAngulaire = 8;
                    }
                    setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
                }
            });
            playerRef.current.addEventListener("touchend", (ev) => {

                if (onlyImages && currentRotator.current !== "sub") { return }

                if (frames.current > 1) {
                    currentRotator.current = null

                    // calcule le temps et la distance parcourue depuis le dernier changement de direction
                    startTime = new Date().getTime(); //-1;
                    var time = new Date().getTime(); // temps en ms
                    var tempsEcoule = time - accStartTime; // temps écoulé en ms
                    var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels

                    var rayonRotation = canvasRef.current.width;
                    var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                    var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s

                    // fixe un maximum
                    if (vitesseAngulaire > 8) {
                        vitesseAngulaire = 8;
                    }

                    setTimeToStop(1000 * vitesseAngulaire * 1.467 / 6); // temps pour s'arrêter en s
                    setCurrentSpeed(vitesseAngulaire);
                    setStartSpeed(vitesseAngulaire);
                }
            });
            playerRef.current.addEventListener("touchstart", (ev) => {

                if (frames.current > 1) {
                    currentRotator.current = onlyImages ? "sub" : "main";

                    // securites
                    if (typeof window.getSelection === 'function'
                        && typeof window.getSelection().empty === 'function') {
                        window.getSelection().empty();
                    }

                    // Pour les tactiles on tourne avec un doigt, on déplace avec deux
                    if (ev.touches) {
                        if (ev.touches.length > 1) {
                            return; // Pas le cas sur D3d
                        }
                    }

                    const x = ev.touches[0].screenX
                    const y = ev.touches[0].screenY

                    setStartSpeed(0);
                    setCurrentSpeed(0);
                    clientX = x;
                    accStartTime = new Date().getTime();
                    accStartX = x;

                    clientY = y;
                    setAccStartY(y)

                    targetRotation = rotation;
                    mouseXOnMouseDown.current = x - window.innerWidth / 2;
                }

            });

            window.addEventListener("mousemove", (ev) => {

                if (isDraggingOnMouse.current && frames.current > 1) {

                    const x = ev.screenX
                    const y = ev.screenY

                    clientX = x;
                    clientY = y;

                    var mouseXTmp = x - window.innerWidth / 2;

                    direction = 'gauche';
                    setDirectionSt('gauche')
                    if ((mouseX.current - mouseXTmp) < 0) {
                        direction = 'droite';
                        setDirectionSt('droite')
                    }

                    if (direction != accStartDir) {
                        accStartDir = direction;
                        accStartTime = new Date().getTime();
                        accStartX = clientX;
                    }
                    mouseX.current = mouseXTmp;

                    // calcule la rotation en fonction de la distance parcourue
                    var largeurCanvas = canvasRef.current.width;
                    var distance = (mouseX.current - mouseXOnMouseDown.current) / .3; // correction de la vitesse
                    var angle = distance * 180 / largeurCanvas;
                    targetRotation = (targetRotationOnMouseDown.current + angle) % 360;
                    targetRotation = Math.floor(targetRotation);
                    if (targetRotation > 360) {
                        targetRotation = targetRotation - 360;
                    }
                    if (targetRotation < 0) {
                        targetRotation = targetRotation + 360;
                    }
                    setTargetRotationSt(targetRotation);
                    setRotation(targetRotation);

                    var time = new Date().getTime(); // temps en ms
                    var tempsEcoule = time - accStartTime; // temps écoulé en ms
                    var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
                    var rayonRotation = canvasRef.current.width;
                    var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                    var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
                    // fixe un maximum
                    if (vitesseAngulaire > 8) {
                        vitesseAngulaire = 8;
                    }
                    setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
                } else {
                    isDraggingOnMouse.current = false;
                }
            });
            window.addEventListener("mouseup", (ev) => {
                // calcule le temps et la distance parcourue depuis le dernier changement de direction
                if (frames.current > 1) {
                    startTime = new Date().getTime(); //-1;
                    var time = new Date().getTime(); // temps en ms
                    var tempsEcoule = time - accStartTime; // temps écoulé en ms
                    var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels

                    var rayonRotation = canvasRef.current.width;
                    var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                    var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s

                    // fixe un maximum
                    if (vitesseAngulaire > 8) {
                        vitesseAngulaire = 8;
                    }

                    setTimeToStop(1000 * vitesseAngulaire * 1.467 / 6); // temps pour s'arrêter en s
                    setCurrentSpeed(vitesseAngulaire);
                    setStartSpeed(vitesseAngulaire);
                    isDraggingOnMouse.current = false;
                } else {
                    isDraggingOnMouse.current = false;
                }

            });
            playerRef.current.addEventListener("mousedown", (ev) => {
                // securites
                if (typeof window.getSelection === 'function'
                    && typeof window.getSelection().empty === 'function') {
                    window.getSelection().empty();
                }

                if (frames.current > 1) {
                    // Pour les tactiles on tourne avec un doigt, on déplace avec deux
                    const x = ev.screenX
                    const y = ev.screenY

                    setStartSpeed(0);
                    setCurrentSpeed(0);
                    clientX = x;
                    accStartTime = new Date().getTime();
                    accStartX = x;

                    clientY = y;
                    setAccStartY(y)

                    targetRotation = rotation;
                    mouseXOnMouseDown.current = x - window.innerWidth / 2;
                    isDraggingOnMouse.current = true;
                }
            });
        }

    }, [programme])


    useEffect(() => {

        var factor = 1;
        var easeOut = function (s) {
            // if (d < 0) {
            //     return b;
            // }
            return s * 0.90
            // return c * ((t = t / d - 1) * t * ((factor + 1) * t + factor) + 1) + b;
        };


        // var prevTime = new Date().getTime();

        var time = new Date().getTime();
        // var elapsedTime = time - prevTime;
        // prevTime = time;


        if (startSpeed > 0 && currentSpeed > 0) {

            var angle = (25 / 20) * currentSpeed;
            var timeFromStart = (time - startTime);

            // var value = easeOut(25, startSpeed, -startSpeed, timeToStop);
            var value = easeOut(currentSpeed);

            setRotation((r) => {
                let rSave = r;

                if (directionSt == 'droite') {
                    rSave += angle;
                } else {
                    rSave -= angle;
                }
                rSave = Math.floor(rSave);
                if (rSave > 360) {
                    rSave = rSave - 360;
                }
                if (rSave < 0) {
                    rSave = rSave + 360;
                }
                return rSave
            })

            setTimeout(() => {
                if (value < 0.5) {
                    setCurrentSpeed(0);
                    // Parent.fireEvent('onSceneRotateComplete');
                } else {
                    setCurrentSpeed(value);
                }
            }, 5)

        }
    }, [currentSpeed, directionSt, startSpeed])


    useEffect(() => {
        var step = Math.floor(rotation * frames.current / 360);
        setFrame(step);
    }, [rotation])

    useEffect(() => { // Rotation et baisse de resolution suivant la vitesse
        if (currentSpeed !== 0) {

            if (preloaded === null) {
                setResolution(10);
                // rotate(0);
                return;
            }

            if (preloaded) {
                let sp = Math.trunc(currentSpeed) != NaN ? Math.trunc(currentSpeed) : 1;
                sp = sp === 0 ? 1 : sp
                setResolution(sp);
            } else {
                setResolution(10);
            }
            // rotate(speed);
        }
    }, [rotation, preloaded])


    useEffect(() => { // Appel de la HD si arret de la rotation + retrait vignette lot a la rotation
        if (currentSpeed === 0) {
            targetRotationOnMouseDown.current = rotation
            setResolution(preloaded);
            setTimeout(() => { setResolution(0) }, 10)
        } else {
            if (!onlyImages) {
                if (ficheProjetRef.current) {
                    ficheProjetRef.current.className = "closed";
                }
            }
            setPlayerToolTip(null);
        }
        update3D();
    }, [preloaded, currentSpeed])

    useEffect(() => {
        if (medias && floor) {
            if (!onlyImages) {
                setFocale(medias[block][floor].focale);
                // setFicheProjet(null);
            }
        }
    }, [medias, block, floor])

    useEffect(() => { // appel de l'image a afficher

        // Chargement de l'image du player 2D
        const currentImg = new Image();
        if (imgsMemory[useBlock]) {
            if (Object.keys(imgsMemory[useBlock][floor]).includes(`${resolution}`)) {
                if (imgsMemory[useBlock][floor][resolution][frame]) {
                    currentImg.src = imgsMemory[useBlock][floor][resolution][frame].src;
                } else {

                    currentImg.src = `/d3d/${programme}/3D/${type}/${navUrl}/jpg-${resolution}/${convertFrame(frame)}.jpg`;
                }
            } else {
                currentImg.src = `/d3d/${programme}/3D/${type}/${navUrl}/jpg-${resolution}/${convertFrame(frame)}.jpg`;
            }
        } else {
            currentImg.src = `/d3d/${programme}/3D/${type}/${navUrl}/jpg-${resolution}/${convertFrame(frame)}.jpg`;
        }


        const onLoad = () => { // Fonction appelee une fois l'image chargee
            setRender(currentImg);
        }

        currentImg.addEventListener('load', onLoad)
        return () => { currentImg.removeEventListener('load', onLoad) }


    }, [imgsMemory, frame, navUrl, useBlock, resolution, programme])

    useEffect(() => { // Mise a jour du player 2D
        if (render) {
            setPlayer3D(null);
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d', { willReadFrequently: true });
            const [offsetX, offsetY, canvasWidth, canvasHeight] = getPlayerSizes(canvas);
            context.drawImage(render, offsetX, offsetY, canvasWidth * zoom, canvasHeight * zoom);
            setOffsetWidth(offsetX);
            setOffsetHeight(offsetY);
            setSizeWidth(canvas.width * zoom);
            setSizeHeight(canvas.height * zoom);
            update3D();
        }
    }, [render, imgStyle, focale, ficheProjet])

    useEffect(update3D, [searchResult, setFicheProjet, ficheProjet])

    useEffect(() => { //Comportement specifique au moment du zoom
        const canvas = canvasRef.current;

        const canvas3D = canvas3DRef.current;
        if (canvas3D) {
            const context = canvas.getContext('2d', { willReadFrequently: true });

            const [offsetX, offsetY, canvasWidth, canvasHeight] = getPlayerSizes(canvas);
            context.drawImage(render, offsetX, offsetY, canvasWidth * zoom, canvasHeight * zoom);

            const player = playerRef.current;

            const w = canvas.width * zoom;
            const h = canvas.height * zoom;

            setPlayer3DSizes(w, h, -(w - player.offsetWidth) / 2, -(h - player.offsetHeight) / 2)

            setOffsetWidth(offsetX);
            setOffsetHeight(offsetY);
            setSizeWidth(canvas.width * zoom);
            setSizeHeight(canvas.height * zoom);
            update3D();
        }
    }, [zoom])

    useEffect(() => {
        if (forcedBlock) {
            setUseBlock(forcedBlock)
        } else {
            setUseBlock(block)
        }
    }, [block, forcedBlock])

    useEffect(() => { // Degradation rapide de la resolution au changement de block pour eviter les cassures
        setResolution(preloaded);
        setTimeout(() => {
            setResolution(0);
        }, 50)
    }, [block])

    useEffect(() => {
        if (block === "Vue générale") {
            if (Object.keys(imgsMemory).includes(block)) {
                if (imgsMemory[block]["Aerien"]["156"].length >= 90) {
                    frames.current = 90;
                }
            }
        } else {
            frames.current = numFrames;
        }
    }, [block, imgsMemory])

    return <><div ref={playerRef} id={`player`} style={{ height: `${onlyImages ? `${sizeHeight}px` : '100%'}` }} onWheel={() => { }/*handleWheel*/}>
        <canvas
            ref={canvasRef}
            id="player-2D"
            className={imgStyle}
        // style={{
        //     webkitUserSelect: 'none',
        //     webkitTouchCallout: 'none',
        //     webkitUserDrag: 'none',
        //     webkitTapHighlightColor: "rgba(0,0,0,0)",
        //     mozUserSelect: 'none',
        //     mozTouchCallout: 'none',
        //     mozUserDrag: 'none',
        //     mozTapHighlightColor: "rgba(0,0,0,0)",
        //     msUserSelect: 'none',
        //     msTouchCallout: 'none',
        //     msUserDrag: 'none',
        //     msTapHighlightColor: "rgba(0,0,0,0)",
        //     oUserSelect: 'none',
        //     oTouchCallout: 'none',
        //     oUserDrag: 'none',
        //     oTapHighlightColor: "rgba(0,0,0,0)",
        //     userSelect: 'none',
        //     touchCallout: 'none',
        //     userDrag: 'none',
        //     tapHighlightColor: "rgba(0,0,0,0)"
        // }}
        />

        {!onlyImages &&
            <div id="playerZone" style={{ position: "absolute", width: `${sizeWidth}px`, height: `${sizeHeight}px`, transform: `translate(${offsetWidth}px, ${offsetHeight}px)` }}>
                {player3D}
                {playerToolTip}
                {puces}
                {indicators}
            </div>
        }


        {(!onlyImages && medias && block && floor) &&
            <div id="playerNavigation" style={{ position: "absolute", left: block === "Vue générale" ? "40%" : "22%", transform: block === "Vue générale" ? "translateX(20%)" : "" }}>
                <div className="navigationLvl">
                    <div
                        className={`navigationChoiceView ${!currentMqtLvl1 ? "activ" : ""}`}
                        onClick={() => {
                            setBlock("Vue générale");
                            setCurrentMqtLvl0("Vue générale");
                            setCurrentMqtLvl1(null);
                        }}
                        onTouchEnd={() => {
                            setBlock("Vue générale");
                            setCurrentMqtLvl0("Vue générale");
                            setCurrentMqtLvl1(null);
                        }}
                    >
                        {window.innerWidth <= 992 ? (
                            <div className="navigationChoiceMain">
                                <SvgIcon icon={"picto-avion"} />
                            </div>
                        ) : (
                            <div className="navigationChoiceMain">
                                Vue générale
                            </div>
                        )}
                    </div>
                </div>
                {currentMqtLvl1 &&
                    <div className="navigationLvl">
                        <div
                            className={`navigationChoice ${currentMqtLvl1 ? "activ" : ""}`}
                        /* onClick inutile ?*/
                        // onClick={() => setBlock(currentMqtLvl1)}
                        >{currentMqtLvl1}</div>
                    </div>
                }
            </div>
        }

        {/* {!onlyImages &&
            ficheProjet
        } */}


    </div>

        {
            boussole && <div id="player-boussole" style={{ rotate: `${d3dDatas.default.north + (360.0 / 90.0 * -frame)}deg` }}>
                <SvgIcon icon={"picto-boussole"} />
            </div>
        }

        <div id="player-loading">
            {
                frames.current === 1 ?
                    "Mise en place de la rotation..." :
                    preloaded !== 720 ?
                        `Chargement des résolutions...` : ""
            }
        </div>

        <div id="containerPlayerLegendRotate" style={{ position: "absolute", right: block === "Vue générale" ? "40%" : "22%", transform: block === 'Vue générale' ? 'translateX(-20%)' : '' }}>
            {!onlyImages && (
                <PlayerRotate
                    frames={frames.current}
                    setFrame={setFrame}
                    rotation={rotation}
                    setRotation={setRotation}
                />)
            }

            {(d3dDatas?.maquette?.puces && d3dDatas?.maquette?.puces?.colors && !onlyImages && block !== "Vue générale") && (
                <PlayerLegend
                    title="Légende"
                    legendes={Object.entries(d3dDatas.maquette.puces.colors).map(e => <LegendLine title={e[0]} color={e[1]} />)}
                    useReturn={false}
                />
            )
            }
        </div>
    </>

}

export default Player
