// /////////////////////////////////////////////////////////////
// Fichier pour la gestion du menu principal de D3D
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext } from 'react';
import { navigationContext, parentContext } from '../../../contexts';
import { Link } from 'react-router-dom';
import Button from './../../../components/button/button'



// /////////////////////////////////////////////////////////////
// Component
const HeaderTop = () => {

    const {parent} = useContext(parentContext);
    const {setCurrentLvl2, setFicheProjet, setBlock, setCurrentMqtLvl0, setCurrentMqtLvl1, setDisplay} = useContext(navigationContext)

    return (
    <navigationContext.Consumer>
      {datas => datas &&

        !parent ?
          <Link id="headerTop" to = {`/${datas.d3d}`} onClick = {() => {
            setCurrentLvl2(null);
            setFicheProjet(null);
            // reset partie maquette
            setBlock("Vue générale");
            setCurrentMqtLvl0("Vue générale");
            setCurrentMqtLvl1(null);
            setDisplay("3D");          
          }}>
            <img src = {`/d3d/${datas.d3d}/logos/${datas.d3dDatas.default.logo}`} />
          </Link>
        :
        <div id="headerTop">
          <img src = {`/d3d/${datas.d3d}/logos/${datas.d3dDatas.default.logo}`} />
          <Link to = {`/${parent}`} onClick = {() => {
                setCurrentLvl2(null);
                setFicheProjet(null);
                // reset partie maquette
                setBlock("Vue générale");
                setCurrentMqtLvl0("Vue générale");
                setCurrentMqtLvl1(null);
              }}>
            <Button id = "homeButton" text = {parent} icon = "picto-home"/>
          </Link>
        </div>
      }
    </navigationContext.Consumer>
    )
  }



  // /////////////////////////////////////////////////////////////
// Export
export default HeaderTop;
