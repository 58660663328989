// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la fiche projet des themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 26/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import { useState, useContext, useRef, useEffect } from "react";
// Imports contexts
import {navigationContext} from '../../../contexts';



// /////////////////////////////////////////////////////////////
// Components

const Galerie = ({images = []}) => {

  const { d3d } = useContext(navigationContext);
  const [navIndex, setNavIndex] = useState(0);
  const [draggStart, setDragStart] = useState(0);
  const [carrouselSize, setCarrouselSize] = useState(0);
  const galerie = useRef();
  const carrousel = useRef();

  useEffect(() => {
    setNavIndex(0);
  }, [images])

  useEffect(() => {
    if (galerie.current) {
      setCarrouselSize(galerie.current.offsetWidth);
    }
  }, [galerie.current])

  return (
    <div id = "galerie" ref={galerie} draggable={false}>
      <div id = "galerieCarrousel" ref={carrousel} draggable={false} style={{ width : `${carrouselSize * images.length}px`, transform : `translateX(${navIndex * -carrouselSize}px)`}}>
        {images.map(img => 
          <div className = "galerieMedia"
            key = {img}
            draggable = {true}
            style = {{width : `${carrouselSize}px`}}
            onDragStart = {e => {setDragStart(e.clientX)}}
            onTouchStart = {e => {setDragStart(e.touches[0].clientX)}}
            onDragEnd = {e => {
              setNavIndex(i => {
                const nw = i + ((e.clientX - draggStart > 0) ? -1 : 1);
                if (nw < 0 ) return 0;
                else if (nw >= images.length) return images.length-1;
                return(i + ((e.clientX - draggStart > 0) ? -1 : 1))
              })
            }}
            onTouchEnd = {e => {
              setNavIndex(i => {
                const nw = i + ((e.changedTouches[0].clientX - draggStart > 0) ? -1 : 1);
                if (nw < 0 ) return 0;
                else if (nw >= images.length) return images.length-1;
                return(i + ((e.changedTouches[0].clientX - draggStart > 0) ? -1 : 1))
              })
            }}
          >
            <img 
              key = {`img-${img}`}
              draggable={false}
              src =  {`/d3d/${d3d}/projets/galerie/${img}`} 
            />
          
          </div>
        )}
      </div>

      <div id = "galerieSelector">
        
        {images.map((img, index) => 
          <div 
            key = {`img-${index}`}
            className = {`selector ${navIndex === index ? "current" : ""}`}
            onClick = {() => setNavIndex(index)}
          >
            {navIndex === index ? <div className = "selectorCurrent"></div> : null}
          </div>
        )}
      </div>
    </div>
  )
}


// /////////////////////////////////////////////////////////////
// Export
  export default Galerie;
  