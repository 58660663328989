import React, { useState } from 'react';
import Caption from "../../components/caption/caption"
import SvgIcon from '../svg/svg';

const PlayerLegend = ({ title, legendes, useReturn }) => {
    const [showPlayerLegend, setShowPlayerLegend] = useState(true);

    const handleContainerClick = () => {
        setShowPlayerLegend(!showPlayerLegend);
    };

    return (
        <>
                <div id="containerPlayerLegend" onClick={handleContainerClick}>
                    <SvgIcon icon={"picto-description"} />
                </div>
            {showPlayerLegend && (
                <div id="playerLegend">
                    <Caption
                        title={title}
                        legendes={legendes}
                        useReturn={useReturn}
                    />
                </div>
            )}
        </>
    );
}

export default PlayerLegend;