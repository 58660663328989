// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page Rechercher
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 02/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useEffect, useState } from 'react';
import { navigationContext } from '../../contexts';



// /////////////////////////////////////////////////////////////
// Component
const Result = ({sysName, datas}) => {
    return (
        <div className= 'rechercherResult'>
            <h3>{datas.name}</h3>
            <div className = 'resultDatas'>
                {datas.surfaces && 
                    <div className = 'dataLine'>
                        <label>Surfaces</label>
                        <p>{datas.surfaces}m²</p>
                    </div>
                }

                {datas.date && 
                    <div className = 'dataLine'>
                        <label>Livraison</label>
                        <p>{datas.date}</p>
                    </div>
                }

                {datas.typologie && 
                    <div className = 'dataLine'>
                        <label>Typologie</label>
                        <div>{datas.typologie.map(t => <p>{t}</p>)}</div>
                    </div>
                }
            </div>
        </div>
    )
}

const Rechercher = () => {

    const {d3d, projects} = useContext(navigationContext);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (d3d && projects) {
            setResults(Object.keys(projects).map(prj => <Result sysName = {prj} datas = {projects[prj]}/>))
        }
    }, [projects, d3d])


    return (
        <div id="rechercher">
            <div id="rechercherForm">
                <div id="rechercherFormInputs">
                    <div className="RechercherFilter">
                        <p className="filterLabel">Livraison</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Programmation</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Description</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Label</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Territoire</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Promoteur</p>
                        <p className="filterInput">formulaire</p>
                    </div>

                    <div className="RechercherFilter">
                        <p className="filterLabel">Architecte</p>
                        <p className="filterInput">formulaire</p>
                    </div>
                </div>

                <div id="rechercherFormSubmit">
                    Rechercher
                </div>
            </div>

            <div id="rechercherResults">
                <div id="resultsTitle">
                    Résultats de votre recherche
                </div>
                <div id="resultsList">
                    {results}
                </div>
            </div>
        </div>  
    )
  }


  
// /////////////////////////////////////////////////////////////
// Export
export default Rechercher;
