// /////////////////////////////////////////////////////////////
// Fichier pour la gestion du menu principal de D3D
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
// Imports Contexts
import { navigationContext } from './../../contexts';
// Imports components
import HeaderTop from './headerTop/headerTop';
import HeaderBottom from './headerBottom/headerBottom';
import Button from './../../components/button/button';



// /////////////////////////////////////////////////////////////
// Component
const Header = ({
  top = null,
}) => {

  // Properties
  const { page, displayUrl, subPage, component } = useParams(); // données issues de l'url
  const navigate = useNavigate(); // Gestion de la navigation
  const {
    d3d,
    setD3d,
    d3dDatas,
    currentLvl1,
    setCurrentLvl1,
    display,
    setDisplay,
    currentLvl2,
    setCurrentLvl2,
    ficheProjet,
    mapRef,
    setSearchResult,
    projects
  } = useContext(navigationContext); // donnees globales
  const [toggleButtons, setToggleBtn] = useState(null); // Boutons du niveau 1
  const [toggleButtons2, setToggleBtn2] = useState(null); // Bouton du niveau 2


  // Effects
  useEffect(() => { // Gestion de la page de niveau 1
    if (page) {
      setCurrentLvl1(page)
    }
  }, [page])

  useEffect(() => { // Gestion du mode d'affichage
    if (displayUrl) {
      setDisplay(displayUrl)
    }
  }, [displayUrl])

  useEffect(() => { // Gestion de la page de niveau 2
    if (subPage) {
      setCurrentLvl2(subPage)
    } else { // fix for use of "Decouvrir button"
      if (projects) {
        setSearchResult(Object.values(projects).map(e => e.name));
      }
    }
  }, [subPage])

  useEffect(() => {  // Gestion des boutons du niveau 2

    if (d3dDatas && currentLvl1 && display !== "") {
      setToggleBtn2(
        d3dDatas.headers[currentLvl1].subPages[display].map(btn =>
          <Link
            key={btn.text}
            to={currentLvl2 === btn.route ? `/${d3d}/${display}` : `/${d3d}/${display}/${btn.route}`}
            onClick={() => {
              if (currentLvl2 === btn.route) {
                setCurrentLvl2("");
                setSearchResult(Object.values(projects).map(e => e.name));
              } else { setCurrentLvl2(btn.route) }
            }}
          >
            <Button
              key={`btn-${btn.text}`}
              text={btn.text}
              state={currentLvl2 === btn.route}
              id={`btn-${btn.route}`}
              icon={btn.icon}
            />
          </Link>
        )
      )
    }
  }, [d3dDatas, currentLvl1, currentLvl2, display])

  useEffect(() => { // Gestion des boutons du niveau 1
    if (d3d && d3dDatas) {
      setToggleBtn(
        Object.keys(d3dDatas.headers).map(btn => {
          return <Link
            key={btn.text}
            to={`/${d3d}/${btn}/3D`}
            onClick={() => {
              setCurrentLvl1(btn);
              setCurrentLvl2(null);
              setDisplay("2D");
            }}
          >
            <Button key={`btn-${btn.text}`} text={d3dDatas.headers[btn].text} state={currentLvl1 === btn} icon={d3dDatas.headers[btn].icon} />
          </Link>
        })
      )
    }
  }, [d3d, d3dDatas, currentLvl1, setCurrentLvl1])



  // Render
  return (
    (d3d && d3d !== "only-lyon") ?
      <div className="header">

        <div className="headerContent">
          <HeaderTop logo={top}></HeaderTop>


          {/* <div className={`toggleMenu ${currentLvl1 === "presenter" && "lvl2"}`}>
              {toggleButtons}
            </div> */}

          <HeaderBottom></HeaderBottom>

        </div>


        <div className={`subHeader ${currentLvl1 === "presenter" && "lvl2"}`}>

          {/* Bouton retour au menu */}
          {/* <Link id = "homeButton" to={`/only-lyon`} onClick={() => {setD3d("only-lyon")}}>
              <Button text = "Home" icon={"picto-home"}></Button>
            </Link> */}

          {toggleButtons2 &&
            <div className="toggleMenu">
              <div id="containerToggleMediaDisplay">
                <div id="toggleMediaDisplay">
                  <div id="button2D3D"
                    onClick={() => {
                      setDisplay("2D");
                      if (!d3dDatas.headers[currentLvl1].subPages["2D"].map(x => x.route).includes(subPage)) {
                        setCurrentLvl2("");
                        navigate(`/${d3d}/2D`);
                      } else {
                        navigate(`/${d3d}/2D${subPage ? `/${subPage}` : ""}${component ? `/${component}` : ""}`)
                      }
                    }
                    }
                  >
                    2D
                  </div>
                  <div id="button2D3D"
                    onClick={() => {
                      setDisplay("3D");
                      if (!d3dDatas.headers[currentLvl1].subPages["3D"].map(x => x.route).includes(subPage)) {
                        setCurrentLvl2("");
                        navigate(`/${d3d}/3D`);
                      } else {
                        navigate(`/${d3d}/3D${subPage ? `/${subPage}` : ""}${component ? `/${component}` : ""}`)
                      }
                    }
                    }
                  >
                    3D
                  </div>
                  <Button customClass={`is${display}`} text={display} state={true}></Button>
                </div>
              </div>

              <div id="btnToggles">
                {toggleButtons2}
              </div>
            </div>
          }
        </div>

      </div>
      :
      <></>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default Header;
