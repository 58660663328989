// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la fiche projet des themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 26/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import { useState, useEffect, useContext, useRef } from "react";
// Imports contexts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


// /////////////////////////////////////////////////////////////
// Components

const BeforeAfterViewer = ({d3d, datas, setBfViewer}) => {

    const container = useRef();
    const before = useRef();
    const beforeText = useRef();
    const afterText = useRef();
    const handle = useRef();
    const widthChange = useRef(0);
    const inset = useRef();

    const [handleLeft, setHandleLeft] = useState(50);
    const [beforeWidth, setBeforeWidth] = useState(50);
    const [insetWidth, setInsetWidth] = useState(2000);

    useEffect(() => {
        widthChange.current = 0;
        if (inset.current) {
            setInsetWidth(container.current.offsetWidth)
            window.onresize = function () {
                setInsetWidth(container.current.offsetWidth)
            }
        }
    }, [inset.current])

    const handleMouseMove = (e) => {
        let containerWidth = container.current.offsetWidth;
        widthChange.current = e.nativeEvent.offsetX;
        let newWidth = widthChange.current * 100 / containerWidth;

        if (e.nativeEvent.offsetX > 10 && e.nativeEvent.offsetX < container.current.offsetWidth - 10) {
            setBeforeWidth(newWidth);
            setHandleLeft(newWidth);
        }
    }

    return <div id="bfViewer">
          <div id="closeViewer" onClick={() => {setBfViewer(null)}}>
              <FontAwesomeIcon icon = {faClose} size={"xl"}/>
            </div>
            <div className="bfContent">
              <div ref={container} className="bal-container" onMouseMove={handleMouseMove}>
                <div className="bal-after">
                <img src = {`/d3d/${d3d}/projets/avant-apres/${datas.images[0]}`}/>
                  <div ref={afterText} className="bal-afterPosition afterLabel">
                      {datas.legends[0]}
                  </div>
                </div>

                <div className="bal-before" ref={before} style={{width : `${handleLeft}%`}}>
                  <div ref={inset} className="bal-before-inset" style={{width : `${insetWidth}px`}}>
                  <img src = {`/d3d/${d3d}/projets/avant-apres/${datas.images[1]}`}/>
                      <div ref={beforeText} className="bal-beforePosition beforeLabel">
                        {datas.legends[1]}
                      </div>
                  </div>
                </div>

                <div ref={handle} className="bal-handle" style={{left : `${beforeWidth}%`}}>
                  <span className=" handle-left-arrow"></span>
                  <span className="handle-right-arrow"></span>
                </div>

              </div>
            </div>
          </div>
}

// /////////////////////////////////////////////////////////////
// Export
  export default BeforeAfterViewer;
  