// /////////////////////////////////////////////////////////////
// Index de district 3D V4
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './style/style.scss'
import District3D from './district3D';
import reportWebVitals from './reportWebVitals';
import {parentContext} from './contexts';


const D3DParent = () => {

  const [parent, setParent] = useState(null);

  return (
    <parentContext.Provider value = {{parent, setParent}}>
      <District3D />
    </parentContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <D3DParent />
  </React.StrictMode>
);

reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorkerRegistration.register();