// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page Presenter/projet
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 18/09/2022
// /////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import {
    faAngleDown,
    faAngleUp,
    faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
// Import Context
import SvgIcon from "../../../components/svg/svg";
import { navigationContext } from "../../../contexts";
import { useFetcher } from "react-router-dom";

// /////////////////////////////////////////////////////////////
// Component

const Resutl = ({ title, surface, date, link }) => {
    const {
        d3dDatas,
        d3d,
        setFicheProjet,
        ficheProjetRef,
        mapRef,
        setBlock,
        currentMqtLvl0,
        setCurrentMqtLvl0,
        currentMqtLvl1,
        setCurrentMqtLvl1,
        setSelectedPoi,
    } = useContext(navigationContext);

    const [img, setImg] = useState(null);


    useEffect(() => {
        const imgSrc = new Image();
        imgSrc.src = `/d3d/${d3d}/projets/previews/${link}.jpg`;
        imgSrc.onload = () => {
            setImg(<img src={`/d3d/${d3d}/projets/previews/${link}.jpg`}></img>);
        };
    }, [link]);

    return (
        <div
            className="result"
            onClick={() => {
                setFicheProjet(title);
                setSelectedPoi(title);
                if (ficheProjetRef.current) {
                    ficheProjetRef.current.className = "";
                }
                if (mapRef.current) {
                    mapRef.current.setZoom(16);
                    fetch(`/d3d/${d3d}/2D/projets/projets.json`)
                        .then((response) => response.json())
                        .then((datas) => {
                            const theGoodPoi = datas.puces.filter(
                                (puce) => puce.name === title
                            );
                            if (theGoodPoi.length > 0) {
                                mapRef.current.setCenter({
                                    lng: theGoodPoi[0].coordinates[1] + 0.0017,
                                    lat: theGoodPoi[0].coordinates[0],
                                });
                            }
                        });
                }
                fetch(`/d3d/${d3d}/3D/media.json`)
                    .then((response) => response.json())
                    .then((datas) => {
                        for (const blck of Object.keys(datas)) {
                            for (const blckPoi of Object.keys(datas[blck]["Aerien"].puces)) {
                                if (blckPoi === title) {
                                    setBlock(blck);
                                    setCurrentMqtLvl0(null);
                                    setCurrentMqtLvl1(blck);
                                }
                            }
                        }
                    });
            }}
        >
            <div className="resultDatas">
                <h3>{title}</h3>
                <div>
                    {surface && (
                        <p>
                            <strong>Surface : </strong>
                            {surface}m²
                        </p>
                    )}
                    {date && (
                        <p>
                            <strong>Livraison : </strong>
                            {date}
                        </p>
                    )}
                </div>
            </div>
            {img && <div className="resultImages">{img}</div>}
        </div>
    );
};

const Projet = () => {
    const { d3dDatas, d3d, miniSearch, setMiniSearch, setSearchResult } =
        useContext(navigationContext);
    const [projets, setProjets] = useState(null);
    const [scrollValue, setScrollValue] = useState(0);
    const yearRange = [2005, 2030];
    const [minYear, setMinYear] = useState(
        miniSearch.minYear ? miniSearch.minYear : 2005
    );
    const [maxYear, setMaxYear] = useState(
        miniSearch.maxYear ? miniSearch.maxYear : 2030
    );
    const scroller = useRef();
    const typeRef = useRef();
    const minSurfaceRef = useRef();
    const maxSurfaceRef = useRef();
    const moaRef = useRef();
    const moeRef = useRef();
    const stateRef = useRef();
    const formRef = useRef();
    const [showForm, setShowForm] = useState(false);
    const isResponsive = window.innerWidth < 992;
    const [phasage, setPhasage] = useState(null);
    const [typologie, setTypologie] = useState(["Tous"]);
    const [arrayTypo, setArrayTypo] = useState(["Tous"]);

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const anim = (from, to) => {
        let current = from;

        const a = setInterval(() => {
            if (from < to) {
                current += 4;
                if (current > to) {
                    clearInterval(a);
                    return;
                }
            } else {
                current -= 4;
                if (current < to) {
                    clearInterval(a);
                    return;
                }
            }

            scroller.current.scrollTop = current;
        }, 1);
    };

    const getProjets = (noFilter) => {
        fetch(`/d3d/${d3d}/projets/projets.json`, { mode: "cors" })
            .then((response) => response.json())
            .then((datas) => {
                let prjs;
                if (noFilter === true) {
                    prjs = Object.keys(datas.projets);
                } else {
                    prjs = Object.keys(datas.projets).filter((projet) => {
                        const prjSur = parseInt(datas.projets[projet].surfaces)
                            ? parseInt(datas.projets[projet].surfaces)
                            : 0;

                        const miVal = parseInt(miniSearch.minSurface)
                            ? miniSearch.minSurface
                            : 0;

                        const maxVal = parseInt(miniSearch.maxSurface)
                            ? miniSearch.maxSurface
                            : 999999999999;

                        const correctMoa = miniSearch.moa
                            ? datas.projets[projet]["maitre d'ouvrage"] &&
                            datas.projets[projet]["maitre d'ouvrage"]
                                .map((o) => o.toLowerCase())
                                .includes(miniSearch.moa.toLowerCase())
                            : true;

                        const correctMoe = miniSearch.moe
                            ? datas.projets[projet]["maitre d'oeuvre"] &&
                            datas.projets[projet]["maitre d'oeuvre"]
                                .map((o) => o.toLowerCase())
                                .includes(miniSearch.moe.toLowerCase())
                            : true;

                        return (
                            prjSur >= miVal &&
                            prjSur <= maxVal &&
                            correctMoa &&
                            correctMoe &&
                            datas.projets[projet].typologie !== null &&
                            (!datas.projets[projet]["date"] ||
                                (parseInt(datas.projets[projet]["date"]) >= minYear &&
                                    parseInt(datas.projets[projet]["date"]) <= maxYear)) &&
                            (miniSearch.type === "Tous" ||
                                datas.projets[projet].typologie.includes(miniSearch.type)) &&
                            (miniSearch.state === "Tous" ||
                                datas.projets[projet].phasage.includes(miniSearch.state))
                        );
                    });
                }

                const newTypoArray = [...typologie];
                Object.values(datas.projets).forEach((p) => {
                    p.typologie.forEach((t) => {
                        if (!newTypoArray.includes(t)) {
                            newTypoArray.push(t);
                        }
                    });
                });
                setTypologie(newTypoArray);

                setSearchResult(prjs.map((projet) => datas.projets[projet].name));

                setProjets(
                    prjs.map((projet) => (
                        <Resutl
                            key={projet}
                            title={datas.projets[projet].name}
                            surface={datas.projets[projet].surfaces}
                            date={datas.projets[projet].date}
                            link={projet}
                        />
                    ))
                );
            });

        setShowForm(!showForm);
    };

    useEffect(() => {
        if (d3dDatas) {
            fetch(`/d3d/${d3d}/programme.json`, { mode: "cors" })
                .then((response) => response.json())
                .then((datas) => {
                    setPhasage(datas.phasage);
                });
        }
    }, [d3d]);

    useEffect(() => {
        if (d3dDatas) {
            if (d3dDatas.maquette && d3dDatas.maquette.puces.colors) {
                const entries = Object.entries(d3dDatas.maquette.puces.colors);
                entries.unshift(["Tous"]);
                setArrayTypo(entries);
            } else {
                setTypologie(["Tous"]);
            }
            getProjets(false);
        }
    }, [d3dDatas]);


    useEffect(() => {
        setMiniSearch({ ...miniSearch, maxYear: maxYear });
    }, [maxYear]);

    useEffect(() => {
        setMiniSearch({ ...miniSearch, minYear: minYear });
    }, [minYear]);


    const scrollUp = () => {
        const resultsScroller = document.getElementById('resultsScroller');
        if (resultsScroller && !isResponsive) {
            resultsScroller.scrollTop -= 102;
        }
        else {
            resultsScroller.scrollTop -= 75;
        }
    }

    const scrollDown = () => {
        const resultsScroller = document.getElementById('resultsScroller');
        if (resultsScroller && !isResponsive) {
            resultsScroller.scrollTop += 102;
        }
        else {
            resultsScroller.scrollTop += 75;
        }
    }

    return (
        <div id="projet">
            <div id="projetSearch">
                {isResponsive && !showForm &&
                    <>
                        <div>
                            <div className="toggleFormIcon" ref={formRef} onClick={toggleForm}>
                                <SvgIcon icon={"picto-settings-1"} />
                            </div>
                        </div>
                        {formRef.current &&
                            <div id="projetSearchResults"
                                style={{ height: `calc(100% - 10px)` }}
                            >

                                {(projets && projets.length > 4) &&
                                    <div id="previousResults">
                                        <FontAwesomeIcon icon={faAngleUp} onClick={() => { setScrollValue(v => v - 108 <= 0 ? 0 : v - 108) }} />
                                    </div>
                                }
                                <div ref={scroller} id="resultsScroller" onWheel={(e) => {
                                    // e.preventDefault();
                                    if (e.deltaY < 0) {
                                        setScrollValue(v => v - 108 <= 0 ? 0 : v - 108)
                                    } else {
                                        setScrollValue(v => v + 108 >= (projets.length - 4) * 108 ? v : v + 108)
                                    }
                                }}>
                                    {projets}
                                </div>
                                {(projets && projets.length > 4) &&
                                    <div id="nextResults">
                                        <FontAwesomeIcon icon={faAngleDown} onClick={() => { setScrollValue(v => v + 108 >= (projets.length - 4) * 108 ? v : v + 108) }} />
                                    </div>
                                }
                            </div>
                        }
                    </>
                }

                <div
                    id="projetSearchForm"
                    ref={formRef}
                    style={{ display: isResponsive && !showForm ? "none" : "flex" }}
                >
                    <div id="searchTitle">
                        <div id="searchTitleText">Projets</div>
                        <div id="searchRefresh">
                            <FontAwesomeIcon
                                icon={faRefresh}
                                onClick={() => {
                                    setMiniSearch(() => {
                                        return {
                                            type: "Tous",
                                            state: "Tous",
                                            minSurface: null,
                                            maxSurface: null,
                                            moa: null,
                                            moe: null,
                                            minYear: 2005,
                                            maxYear: 2030,
                                        };
                                    });
                                    typeRef.current.value = "Tous";
                                    stateRef.current.value = "Tous";
                                    minSurfaceRef.current.value = null;
                                    maxSurfaceRef.current.value = null;
                                    moaRef.current.value = null;
                                    moeRef.current.value = null;
                                    setMinYear(2005);
                                    setMaxYear(2030);
                                    getProjets(true);

                                    if (window.innerWidth <= 992) {
                                        setShowForm(showForm);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div id="searchInputs">
                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("type") && (
                                <div className="searchLine">
                                    <label>Type</label>
                                    <select
                                        ref={typeRef}
                                        defaultValue={miniSearch.type}
                                        onChange={(e) => {
                                            setMiniSearch({ ...miniSearch, type: e.target.value });
                                        }}
                                    >
                                        {d3dDatas && d3dDatas.maquette && d3dDatas.maquette.puces.colors ? (
                                            arrayTypo.map((t) => {
                                                return <option key={t} value={t[0]}>{t[0]}</option>;
                                            })
                                        ) : (
                                            typologie.map((t) => (
                                                <option key={t} value={t}>
                                                    {t}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            )}

                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("etat") && (
                                <div className="searchLine" id="searchResp">
                                    <label>Etat</label>
                                    <select
                                        ref={stateRef}
                                        defaultValue={miniSearch.state}
                                        onChange={(e) => {
                                            setMiniSearch({ ...miniSearch, state: e.target.value });
                                        }}
                                    >
                                        {phasage &&
                                            phasage.map((v) => (
                                                <option key={v} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        {phasage ? null : (
                                            <>
                                                <option value={"Tous"}>Tous</option>
                                                <option value={"A venir"}>A venir</option>
                                                <option value={"En travaux"}>En travaux</option>
                                                <option value={"Réalisé"}>Réalisé</option>
                                            </>
                                        )}
                                    </select>
                                </div>
                            )}

                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("surface") && (
                                <div className="searchLine" id="searchResp">
                                    <label>Surface</label>
                                    <div className="searchSurface">
                                        <input
                                            ref={minSurfaceRef}
                                            defaultValue={miniSearch.minSurface}
                                            type="number"
                                            placeholder="min."
                                            onChange={(e) => {
                                                setMiniSearch({
                                                    ...miniSearch,
                                                    minSurface: e.target.value,
                                                });
                                            }}
                                        ></input>
                                        <input
                                            ref={maxSurfaceRef}
                                            defaultValue={miniSearch.maxSurface}
                                            type="number"
                                            placeholder="max."
                                            onChange={(e) => {
                                                setMiniSearch({
                                                    ...miniSearch,
                                                    maxSurface: e.target.value,
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>
                            )}

                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("livraison") && (
                                <div className="searchLine" id="searchResp">
                                    <label>Livraison</label>
                                    <div className="searchLivraison">
                                        <div className="livraison-label">
                                            <label id="minLbl">{minYear}</label>
                                            <label id="maxLbl">{maxYear}</label>
                                        </div>
                                        <div className="livraison-slider">
                                            <div id="searchDates">
                                                <input
                                                    id="minDate"
                                                    defaultValue={yearRange[0]}
                                                    value={miniSearch.minYear}
                                                    min={yearRange[0]}
                                                    max={yearRange[1]}
                                                    className="slider"
                                                    type="range"
                                                    onChange={(e) => {
                                                        if (e.target.value >= maxYear) {
                                                            setMinYear(maxYear - 1);
                                                        } else {
                                                            setMinYear(e.target.value);
                                                        }
                                                    }}
                                                />
                                                <input
                                                    id="maxDate"
                                                    defaultValue={yearRange[1]}
                                                    value={miniSearch.maxYear}
                                                    min={yearRange[0]}
                                                    max={yearRange[1]}
                                                    className="slider"
                                                    type="range"
                                                    onChange={(e) => {
                                                        if (e.target.value <= minYear) {
                                                            setMaxYear(parseInt(minYear) + 1);
                                                        } else {
                                                            setMaxYear(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("moa") && (
                                <div className="searchLine" id="searchResp">
                                    <label>MOA</label>
                                    <input
                                        ref={moaRef}
                                        defaultValue={miniSearch.moa}
                                        onChange={(e) => {
                                            setMiniSearch({ ...miniSearch, moa: e.target.value });
                                        }}
                                        placeholder="Maîtrise d'ouvrage..."
                                    ></input>
                                </div>
                            )}

                        {d3dDatas.rechercher &&
                            d3dDatas.rechercher.criteres.includes("moe") && (
                                <div className="searchLine" id="searchResp">
                                    <label>MOE</label>
                                    <input
                                        ref={moeRef}
                                        defaultValue={miniSearch.moe}
                                        onChange={(e) => {
                                            setMiniSearch({ ...miniSearch, moe: e.target.value });
                                        }}
                                        placeholder="Maîtrise d'oeuvre..."
                                    ></input>
                                </div>
                            )}

                        <div className="searchButton" onClick={() => getProjets(false)}>
                            Rechercher
                        </div>
                    </div>
                </div>

                {!isResponsive && formRef.current && (
                    <div
                        id="projetSearchResults"
                        style={{
                            height: `calc(100% - ${formRef.current.offsetHeight}px)`,
                            display: isResponsive ? "none" : "flex",
                        }}
                    >
                        {projets && projets.length > 4 && (
                            <div id="previousResults">
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    onClick={() => {
                                        scrollUp();
                                    }}
                                />
                            </div>
                        )}
                        <div
                            ref={scroller}
                            id="resultsScroller"
                        >
                            {projets}
                        </div>
                        {projets && projets.length > 4 && (
                            <div id="nextResults">
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    onClick={() => {
                                        scrollDown();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

// /////////////////////////////////////////////////////////////
// Export
export default Projet;
