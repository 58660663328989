// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page Presenter/themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 17/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
// Imports contexts
import { navigationContext } from './../../../contexts'
// Imports components
import LegendLine from './../../../components/caption/legendLine/legendLine';
import PlayerLegend from '../../../components/player/playerLegend';
import LinkedButtonLong from '../../../components/buttonLong/linkedButtonLong';



// /////////////////////////////////////////////////////////////
// Component
const Themes = () => {

  // Properties
  const { d3d, d3dDatas, display } = useContext(navigationContext);
  const { theme } = useParams();
  const [themes, setThemes] = useState(null);
  const [legendes, setLegendes] = useState(null);
  const [y, setY] = useState(0);

  const widthMenuThemes = useRef(null);
  const [width, setWidth] = useState(null);

  // Effects
  useEffect(() => {
    if (theme) {
      setLegendes(
        d3dDatas.themes[theme].legends.map(legende =>
          <LegendLine
            title={legende.title}
            color={legende.color}
          />
        )
      )
    }
  }, [d3dDatas, theme])

  useEffect(() => { //
    if (d3dDatas) {
      setThemes(
        Object.keys(d3dDatas.themes).map(
          themeData => <LinkedButtonLong
            key={d3dDatas.themes[themeData].texte}
            text={d3dDatas.themes[themeData].texte}
            link={`/${d3d}/${display}/themes/${themeData}`}
            state={theme == themeData}
            icon={d3dDatas.themes[themeData].icon}
          />
        )
      )

      setTimeout(() => {
        const parentBtn = document.getElementById("btn-themes");
        if (parentBtn) {
          setY(parentBtn.parentElement.parentElement.offsetTop + parentBtn.offsetTop - (Object.keys(d3dDatas.themes).length / 2 * 49));
        }
      }, 10)
      setWidth(widthMenuThemes.current.clientWidth);
    }
  }, [d3dDatas, theme])


  // Render
  return (
    <div id="themes" ref={widthMenuThemes}>
      <div id="themesCaption">
        {legendes && legendes.length ?
          <PlayerLegend
            title={d3dDatas.themes[theme].texte}
            icon={d3dDatas.themes[theme].icon}
            legendes={legendes}
            useReturn={false}
          />
           :
          null
        }
      </div>
      <div id="themesSelection" style={{ top: `${y}px` }}>
        {themes}
      </div>


    </div>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default Themes;
