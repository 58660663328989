// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des fonds de carte
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 18/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Map, { Marker, NavigationControl } from "react-map-gl"
// Contexts
import { navigationContext } from './../../contexts'
// Import components
import Poi from "./poi/poi"
import { faCarRear } from '@fortawesome/free-solid-svg-icons'
import LegendLine from '../../components/caption/legendLine/legendLine'
import PlayerLegend from '../../components/player/playerLegend'




// /////////////////////////////////////////////////////////////
// Component
const MediaCarte = ({ page }) => {

  // Properties
  const {
    d3d,
    d3dDatas,
    setFicheProjet,
    ficheProjetRef,
    mapRef,
    searchResult,
    selectedPoi,
    currentLvl2,
    setCurrentLvl2,
    setSelectedPoi,
    projects
  } = useContext(navigationContext);
  const { theme, carte, projet } = useParams();
  const [isMapLoaded, setIsMapLoaded] = useState(false); // etat de chargement de la map
  const [pois, setPois] = useState([]); // liste des puces de la map
  const [zoomMap, setZoomMap] = useState(); // zoom courant
  const [displayToolTip, setDisplayToolTip] = useState(false); // affichage des tooltips des puces
  const [displayControl, setDisplayControl] = useState(
    <NavigationControl position='top-right' showCompass={true} />
  ); //affichage des controlles de la carte
  const [mapStyle, setMapStyle] = useState('mapbox://styles/asylum-69/clb2dnzlq005015r1f2clztxb'); // style de la carte
  const [zoomLevel, setZoomLevel] = useState();


  // Functions
  const removeAllLayerAndSource = () => { // Retrait de tous les layers de la map

    const currentMap = mapRef.current.getMap();

    // Retrait des layers
    currentMap.getStyle().layers.forEach((element) => {
      if (element.source !== "composite" && element.id !== "land") {
        currentMap.removeLayer(element.id);
      }
    })
    // Retrait des sources
    Object.keys(currentMap.getStyle().sources).forEach(element => {
      if (element !== "composite") {
        currentMap.removeSource(element)
      }
    })
  }

  const setMapFromDatas = (datas) => { // Mise en place des donnees de la carte par theme ou carte et placement des traces
    mapRef.current.getMap().setMaxZoom(datas.global.maxzoom);
    mapRef.current.getMap().setMinZoom(datas.global.minzoom);
    mapRef.current.getMap().setMaxBounds([
      { lng: datas.global.boundleftbottom[1], lat: datas.global.boundleftbottom[0] },
      { lng: datas.global.boundrighttop[1], lat: datas.global.boundrighttop[0] }
    ]);
    mapRef.current.setCenter({ lng: datas.global.coordinates[1], lat: datas.global.coordinates[0] })
    // Condition pour ajuster le zoom si la largeur de la fenêtre est inférieure ou égale à 992 pixels
    if (window.innerWidth <= 992) {
      mapRef.current.setZoom(datas.global.minzoom);
    } else {
      mapRef.current.setZoom(datas.global.zoom);
    }

    // mapRef.current.setZoom(datas.global.zoom);
    setMapStyle(datas.global.mapstyle);
    //setDisplayToolTip(datas.global.zoom > checkZoomTooltips(datas.global.zoomTooltips));
    if (datas.layers) {
      addLayers(datas)
    }
  }

  const setPoisFromDatas = (datas) => { // Mise en place des puces par theme ou carte
    setPois(datas.puces
      .filter(puce => {
        if (page === "projets") {
          return (searchResult.includes(puce.name))
        } else { return true }
      })
      .map(
        (puce, i) => <Marker key={`${i}-${puce.name}`}
          latitude={puce.coordinates[0]}
          longitude={puce.coordinates[1]}>

          <Poi
            color={page !== "projets" ? checkLegend(datas.global.color, datas.legends, puce.legend) : check2DProjectsLegend(puce.name)}
            name={puce.name}
            textIsVisible={displayToolTip}
            state={selectedPoi == puce.name ? "current" : ""}
            textPuce={puce.projet}
            icon={checkPuceIcon(puce.icon)}
            context={d3d}
            onClick={puce.projet && puce.projet !== null ? () => {
              createFicheProjetOnPoiClick(puce.name, puce.projet);
              setSelectedPoi(puce.name)
            } : () => { }}
            type={"map"}
          />
        </Marker>
      ))
  }

  const addLayers = (datas) => { // Ajout d'un layer

    const currentMap = mapRef.current.getMap();

    datas.layers.forEach(layer => {
      switch (layer.layer.type) {
        case "line": // Gestion d'ajout de trace
          let dasharray;
          if (layer.layer.dasharray == undefined) {
            dasharray = [];
          } else {
            dasharray = layer.layer.dasharray;
          }
          // Ajout de la source
          currentMap.addSource(layer.source.id, {
            type: layer.source.type,
            url: layer.source.url
          })
          // Ajout du layer
          currentMap.addLayer({
            id: layer.layer.id,
            type: layer.layer.type,
            source: layer.layer.source,
            layout: {
              visibility: layer.layer.visibility,

            },
            paint: {
              "line-color": layer.layer.linecolor,
              "line-width": layer.layer.linewidth
            },
            "source-layer": layer.layer.sourcelayer,
            minzoom: layer.layer.minzoom,
            maxzoom: layer.layer.maxzoom,
          })
          break;

        case "fill": // Gestion d'ajout de zoning plein
          // Ajout de la source
          currentMap.addSource(layer.source.id, {
            type: layer.source.type,
            url: layer.source.url
          })
          // Ajout du layer
          currentMap.addLayer({
            id: layer.layer.id,
            type: layer.layer.type,
            source: layer.layer.source,
            layout: {
              visibility: layer.layer.visibility,
            },
            paint: {
              "fill-color": layer.layer.fillcolor,
              "fill-opacity": layer.layer.fillopacity,
              "fill-outline-color": layer.layer.outlinecolor,
            },
            "source-layer": layer.layer.sourcelayer,
            minzoom: layer.layer.minzoom,
            maxzoom: layer.layer.maxzoom,
          })
          break;

        case "circle": // Gestion d'ajout de cercle
          // Ajout de la source
          currentMap.addSource(layer.source.id, {
            type: layer.source.type,
            url: layer.source.url
          })
          // Ajout du layer
          currentMap.addLayer({
            id: layer.layer.id,
            type: layer.layer.type,
            source: layer.layer.source,
            layout: {
              visibility: layer.layer.visibility,
            },
            paint: {
              "circle-color": layer.layer.circlecolor,
              "circle-radius": layer.layer.circleradius
            },
            "source-layer": layer.layer.sourcelayer,
            minzoom: layer.layer.minzoom,
            maxzoom: layer.layer.maxzoom,
          })
          break;
      }
    })

  }

  const checkLegend = (defaultColor, legends, legend) => { // Verfication de l'usage de legende
    if (!legends || !legend) {
      return defaultColor
    } else if (Object.keys(legends).includes(legend)) {
      return legends[legend]
    }
    return defaultColor
  }

  const check2DProjectsLegend = (puce) => { // Verfication de l'usage de legende

    const projectsNames = Object.entries(projects).map(k => k[1].name)
    const puceProject = projectsNames.findIndex(p => p === puce)
    let color = d3dDatas.default.color

    if (puceProject !== -1 && d3dDatas?.maquette?.puces?.colorType === "typologies") {
      color = d3dDatas.maquette.puces.colors[projects[Object.keys(projects)[puceProject]].typologie[0]]
    }

    return color
  }

  const checkPuceIcon = (icon) => {
    if (icon) {
      return icon
    }
    else {
      return null
    }
  }

  const checkZoomTooltips = (zoom) => { // Verification de l'affichage des tooltips
    if (zoom !== undefined && zoom !== null) {
      return zoom
    }
    else {
      return 14 //Valeur par defaut
    }
  }

  const createFicheProjetOnPoiClick = (projet) => { // Ajout de la fiche projet
    setDisplayControl(null);
    setFicheProjet(null);
    setFicheProjet(projet);
    if (ficheProjetRef.current) {
      ficheProjetRef.current.className = "";
    }
  }

  useEffect(() => {
    if (currentLvl2 === null) {
      setCurrentLvl2("");
    }
  }, [currentLvl2])

  // Effects pour la mise en place de la carte pour chaque page
  useEffect(() => {
    if (isMapLoaded) {
      if (currentLvl2 === "") {
        removeAllLayerAndSource();
        setPois([]);
        fetch(`/d3d/${d3d}/2D/home.json`)
          .then(data => data.json())
          .then(datas => {
            setMapFromDatas(datas);
          })
      }
    }
  }, [currentLvl2, isMapLoaded])

  useEffect(() => { // Themes
    if (isMapLoaded) {
      removeAllLayerAndSource();
      if (theme) {
        fetch(`/d3d/${d3d}/2D/themes/${theme}.json`)
          .then(data => data.json())
          .then(themes => {
            setMapFromDatas(themes);
            setZoomLevel(checkZoomTooltips(themes.global.zoomTooltips));
          })
      }
    }
  }, [theme, isMapLoaded])

  useEffect(() => { // Cartes
    if (isMapLoaded) {
      removeAllLayerAndSource();
      if (carte) {
        fetch(`/d3d/${d3d}/2D/cartes/${carte}.json`)
          .then(data => data.json())
          .then(cartes => {
            setMapFromDatas(cartes);
            setZoomLevel(checkZoomTooltips(cartes.global.zoomTooltips));
          })
      }
    }
  }, [carte, isMapLoaded])


  // //TODO à développer plus tard
  // useEffect(() => { 
  //   if(d3d == "carre-soie") {
  //     if(carte == undefined || theme == undefined) {
  //       if(mapStyle !== "mapbox://styles/asylum-69/clemlsezj005101o38rdwl8wy") {
  //         setMapStyle("mapbox://styles/asylum-69/clemlsezj005101o38rdwl8wy")

  //       }
  //     }
  //   }
  // }, [carte, theme])


  // useEffect(() => { // Projet
  //   if (isMapLoaded) {
  //     removeAllLayerAndSource();
  //     if (projet) {
  //       fetch(`/d3d/${d3d}/projets/cartes/${projet}.json`)
  //         .then(data => data.json())
  //         .then(projets => {
  //           setMapFromDatas(projets);
  //         })
  //     }
  //   }
  // }, [projet, isMapLoaded])

  useEffect(() => {
    if (page === 'projets') {
      if (isMapLoaded) {
        removeAllLayerAndSource();
        fetch(`/d3d/${d3d}/2D/projets/projets.json`)
          .then(data => data.json())
          .then(projets => {
            setMapFromDatas(projets);
            if (selectedPoi) {
              const goodPrj = projets.puces.filter(prj => prj.name === selectedPoi)
              if (goodPrj.length > 0) {
                mapRef.current.setCenter({ lng: goodPrj[0].coordinates[1] + 0.0017, lat: goodPrj[0].coordinates[0] });
                mapRef.current.setZoom(16);
              }
            }
          })
      }
    }
  }, [page, isMapLoaded])


  // Effects pour l'ajout des poi de chaque pages
  useEffect(() => {
    if (isMapLoaded) {
      setPois([]);
      if (theme) {
        fetch(`/d3d/${d3d}/2D/themes/${theme}.json`)
          .then(data => data.json())
          .then(themes => {
            setPoisFromDatas(themes);
          })
      }
    }
  }, [displayToolTip, isMapLoaded, theme, selectedPoi])

  useEffect(() => {
    if (isMapLoaded) {
      setPois([]);
      if (carte) {
        fetch(`/d3d/${d3d}/2D/cartes/${carte}.json`)
          .then(data => data.json())
          .then(cartes => {
            setPoisFromDatas(cartes);
          })
      }
    }
  }, [displayToolTip, isMapLoaded, carte, selectedPoi])

  // useEffect(() => {
  //   if (isMapLoaded) {
  //     setPois([]);
  //     if (projet) {
  //       fetch(`/d3d/${d3d}/projets/cartes/${projet}.json`)
  //         .then(data => data.json())
  //         .then(projets => {
  //           setPoisFromDatas(projets);
  //         })
  //     }
  //   }
  // }, [displayToolTip, isMapLoaded, projet, selectedPoi])

  useEffect(() => {
    if (page === 'projets') {
      if (isMapLoaded) {
        setPois([]);
        fetch(`/d3d/${d3d}/2D/projets/projets.json`)
          .then(data => data.json())
          .then(projets => {
            setPoisFromDatas(projets);
          })
      }
    }
  }, [displayToolTip, isMapLoaded, page, selectedPoi, searchResult])


  useEffect(() => { // gestion de l'affichage des tooltips au changement de zoom
    setDisplayToolTip(zoomMap > zoomLevel || zoomMap > 16);
  }, [zoomMap])


  return (
    <div id="carte">
      <Map
        ref={mapRef}
        mapboxAccessToken='pk.eyJ1IjoiYXN5bHVtLTY5IiwiYSI6ImNqeG9odDJuNjA2amUzb3A5azcyb2VvMWIifQ.n_t7hMWp6igJoUNDqN_h7Q'
        mapStyle={mapStyle}
        initialViewState={{
          zoom: 14
        }}
        onLoad={() => { setIsMapLoaded(true) }}
        onZoom={(e) => { setZoomMap(e.viewState.zoom) }}
      >
        {pois}
        {displayControl}
      </Map>

      {(page === "projets" && d3dDatas?.maquette?.puces) &&
        <div id="carteLegend">
          <PlayerLegend
            title="Légende"
            legendes={Object.entries(d3dDatas.maquette.puces.colors).map(e => <LegendLine title={e[0]} color={e[1]} />)}
            useReturn={false}
          />
        </div>
      }
    </div>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default MediaCarte;
