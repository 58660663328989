// /////////////////////////////////////////////////////////////
// Fichier pour la gestion d'une ligne de légende dans un caption
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 02/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';


const LegendLine = ({title, color}) => {
    return (
        <div className = "legendLine">
            <div className = "linePt" style = {{"backgroundColor" : color}}></div>
            <p>{title}</p>
        </div>
    )
}


export default LegendLine;