// /////////////////////////////////////////////////////////////
// Fichier principal de district 3D V4
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';


// Imports components
import D3DMainRouter from './layout/header/d3dRouter/d3dMainRouter';
import Redirect from './components/redirect/redirect';



// /////////////////////////////////////////////////////////////
// Components

const District3D = () => {

  return (
    <div id="district3D">
        <BrowserRouter>
            <Routes>
                <Route path = "" element = {<Redirect to="https://www.asylum.fr/"/>}/>
                <Route path = ':d3dUrl/*' element = {<D3DMainRouter />}></Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}


 
// /////////////////////////////////////////////////////////////
// Export
export default District3D;
