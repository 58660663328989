// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons de D3D
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';
import SvgIcon from '../svg/svg';




// /////////////////////////////////////////////////////////////
// Component
const Button = ({text, state = false, action = () => {}, customClass = "", id = null, icon = null}) => {
    return (
      <div 
        id = {id ? id : {text}}
        className={`button ${state ? "activ" : ""} ${customClass}`}
        onClick={action}
      >
        {icon && <SvgIcon icon = {icon}/>}
        {text}

      </div>
    )
}


  
// /////////////////////////////////////////////////////////////
// Export
export default Button;
