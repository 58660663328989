// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page Presenter/Cartes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 02/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
// Imports contexts
import { navigationContext } from './../../../contexts'
// Import Components
import LegendLine from './../../../components/caption/legendLine/legendLine';
import LinkedButtonLong from '../../../components/buttonLong/linkedButtonLong';
import PlayerLegend from '../../../components/player/playerLegend';

// /////////////////////////////////////////////////////////////
// Component
const Cartes = () => {

  // contexts
  const { d3d, d3dDatas, display } = useContext(navigationContext);
  // params
  const { carte } = useParams();
  // portperties
  const [cartes, setCartes] = useState(null);
  const [y, setY] = useState(0);
  const [legendes, setLegendes] = useState(null);

  const widthMenuCartes = useRef(null);
  const [width, setWidth] = useState(null);

  // Effects
  useEffect(() => {
    if (carte) {
      setLegendes(
        d3dDatas.cartes[carte].legends.map(legende =>
          <LegendLine
            title={legende.title}
            color={legende.color}
          />
        )
      )

    }
  }, [d3dDatas, carte])


  useEffect(() => { //
    if (d3dDatas) {
      setCartes(
        Object.keys(d3dDatas.cartes).map(
          carteData => <LinkedButtonLong
            key={d3dDatas.cartes[carteData].texte}
            text={d3dDatas.cartes[carteData].texte}
            link={`/${d3d}/${display}/cartes/${carteData}`}
            state={carte == carteData}
            icon={d3dDatas.cartes[carteData].icon}
          />
        )
      )

      setTimeout(() => {
        const parentBtn = document.getElementById("btn-cartes");
        if (parentBtn) {
          setY(parentBtn.parentElement.parentElement.offsetTop + parentBtn.offsetTop - (Object.keys(d3dDatas.cartes).length / 2 * 49));
        }
      }, 10)
      setWidth(widthMenuCartes.current.clientWidth);
    }
  }, [d3dDatas, carte])

  // Render
  return (
    <div id="cartes" ref={widthMenuCartes}>
      <div id="cartesCaption">
        {legendes && legendes.length ?
          <PlayerLegend
            title="Résumé"
            icon={d3dDatas.cartes[carte].icon}
            legendes={legendes}
            useReturn={false}
          />
          :
          null
        }
      </div>
      <div id="cartesSelection" style={{ top: `${y}px` }}>
        {cartes}
      </div>
    </div>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default Cartes;
