// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons de style allongé + lien
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 17/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';
import { Link } from 'react-router-dom';

// Import Components
import ButtonLong from './buttonLong'


// /////////////////////////////////////////////////////////////
// Component
const LinkedButtonLong = ({text, link, state = false, icon = null}) => {
    return (
        <Link to={link}>
            <ButtonLong  text={text} state={state} icon = {icon} />
        </Link>
    )
}

  
// /////////////////////////////////////////////////////////////
// Export
export default LinkedButtonLong;
