import React, { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { navigationContext } from '../contexts';
import { useFetcher, useParams } from 'react-router-dom';
import { programmeContext } from "../contexts";

const GlobalStyle = () => {

    const [color, setColor] = useState("#000000");
    const [fontColor, setFontColor] = useState("#ffffff");
    const [svgColor, setSvgColor] = useState("#ffffff");
    const [d3d, setD3d] = useState(null);
    const { d3dUrl } = useParams();


    const GlobalStyles = createGlobalStyle`

        .toggleFormIcon svg path, circle {fill : ${color}!important;}
        .button:hover, .button.activ, .searchButton {background-color : ${color};}
        .buttonLong:hover, .buttonLong.activ {background-color : ${color};}
        .result:hover {background-color : ${color}!important;}
        .slider::-webkit-slider-thumb, .slider::-webkit-range-thumb {background-color: ${"black"}!important;}
        #searchDates label, #projetSearchResults svg {color : ${color}!important;}  
        .playerProjetsCountInfo {background-color : ${color};}
        #lotHover-base, #lotHover-text {background-color : ${color};}
        #ficheProjet::after {background-color : ${color}!important;}
        .link-btn:hover {background-color : ${color}!important;}
        .bal-beforePosition, .bal-afterPosition {background-color : ${color}!important;}
        .button-nav-responsive.activ {background-color : ${color}!important;}
        #containerPlayerLegend {background-color : ${color}!important;}
        #playerRotate {background-color : ${color}!important;}
        
        .navigationChoice.activ {background-color : ${color}!important; color : ${fontColor}!important;}
        .navigationChoiceView .navigationChoiceMain {background-color : ${'white'}!important; color : ${'black'}!important;}
        .navigationChoiceView:hover .navigationChoiceMain {background-color : ${color}!important; color : ${fontColor}!important;}
        .navigationChoiceView.activ .navigationChoiceMain {background-color : ${color}!important; color : ${fontColor}!important; fill : ${fontColor}!important;}

        .button.activ {color : ${fontColor}!important;}
        .searchButton {color : ${fontColor}!important;}
        #lotHover-text {color : ${fontColor}!important;}
        .puce-text {color : ${fontColor}!important;}

        #btn-projets:hover {color : ${fontColor}!important;}
        #btn-themes:hover {color : ${fontColor}!important;}
        #btn-cartes:hover {color : ${fontColor}!important;}
        #btn-filtres:hover {color : ${fontColor}!important;}

        .buttonLong:hover {color : ${fontColor}!important;}
        .buttonLong.activ {color : ${fontColor}!important;}

        #btn-projets.activ svg path, polygon {fill : ${svgColor}!important;}
        #btn-themes.activ svg path, polygon {fill : ${svgColor}!important;}
        #btn-cartes.activ svg path, polygon {fill : ${svgColor}!important;};}
        #btn-filtres.activ svg path, polygon {fill : ${svgColor}!important;}

        #btn-projets:hover svg path, polygon {fill : ${svgColor}!important;}
        #btn-themes:hover svg path, polygon {fill : ${svgColor}!important;}
        #btn-cartes:hover svg path, polygon {fill : ${svgColor}!important;};}
        #btn-filtres:hover svg path, polygon {fill : ${svgColor}!important;}

        .buttonLong svg path, polygon {fill : #505050!important;}
        .toggleMenu svg path, polygon {fill : #505050!important;}
        .buttonLong:hover svg path, polygon {fill : ${svgColor}!important;}
        .buttonLong.activ svg path, polygon {fill : ${svgColor}!important;}
        `

    const getYiq = (color) => {
        let r = parseInt(color.substring(1, 3), 16);
        let g = parseInt(color.substring(3, 5), 16);
        let b = parseInt(color.substring(5, 7), 16);

        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return yiq;
    }

    const ColorHandler = (color, secondaryColor) => {
        if (secondaryColor && secondaryColor !== "") {
            setSvgColor(secondaryColor);
            setFontColor(secondaryColor);
        } else {
            getYiq(color) >= 128 ? setFontColor("#000000") : setFontColor("#ffffff");
            getYiq(color) >= 128 ? setSvgColor("#505050") : setSvgColor("#ffffff");
        }
        setColor(color);
    }

    useEffect(() => {
        setD3d(d3dUrl);
    }, [d3dUrl]);

    useEffect(() => {
        if (d3d) {
            fetch(`/d3d/${d3d}/programme.json`, { mode: 'cors' })
                .then(response => response.json())
                .then(datas => {
                    ColorHandler(datas.default.color, datas.default.secondary);
                })
                .catch(error => console.log(error));
        }
    }, [d3d]);


    return (
        <navigationContext.Provider value={{ d3d: d3d }}>
            <GlobalStyles />
        </navigationContext.Provider>
    )

}

export default GlobalStyle;