// /////////////////////////////////////////////////////////////
// Fichier pour la gestion d'un encars de legende
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Import context
import { navigationContext } from './../../contexts';
// Import components
import SvgIcon from '../svg/svg';





// /////////////////////////////////////////////////////////////
// Component

const Caption = ({ title, legendes = [], icon = "", useReturn = true, mapMenuWidth, side = "left" }) => {

  const navigate = useNavigate();

  return (
    <div className={`container-caption`}>
    <div className={`caption ${side}`} style={{minWidth: mapMenuWidth }}>
      <div className="captionTitle">
        <p className="captionIcon">
          <SvgIcon icon={icon} />
        </p>
        <h2 className="captionName">{title}</h2>
        {useReturn ?
          <p className="captionReturn" onClick={() => { navigate(-1) }}>retour</p>
          :
          <p className="captionReturn"></p>
        }
      </div>
      <div className="captionContent">
        <h3>Légende</h3>
        {legendes}
      </div>
    </div>
    </div>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default Caption ;
