// ///////////////////////////////////////////////////////////////////////////////
// Hooks pour la gestion du prechargement de la maquette 3D
// ///////////////////////////////////////////////////////////////////////////////
// Creation 17/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////



// Imports system
import React, {useEffect, useState } from 'react';


const usePreloader = (programme) => {
    
    const loaded = {"lots" : {}, "maquette" : {}}; // tableau des images

    const resols = [156, 450, 720]
    const [datas, setDatas] = useState(null);
    const [preloaded, setPreloaded] = useState(null);
    const [imgMemory, setImgMemory] = useState({});

    
    const loadAllFramesImg = (path, block, floor, index, resol, last, board) => {

        const strIndex = index.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});

        const img = new Image();
        img.src = `${path}${resol}/${strIndex}.jpg`;

        if (board[block]) {
            if (board[block][floor]) {
                if (board[block][floor][resol]) {
                    board[block][floor][resol].push(img)
                } else {
                    board[block][floor][resol] = []
                    board[block][floor][resol].push(img)
                }
            } else {
                board[block][floor] = []
                board[block][floor].push(img.src)
                board[block][floor][resol] = []
                board[block][floor][resol].push(img);
            }
        } else {
            board[block] = {}
            board[block][floor] = {}
            board[block][floor][resol] = []
            board[block][floor][resol].push(img);
        }

        img.addEventListener('load', () => {
            loadAllFramesImg(path, block, floor, index+1, resol, last, board)
        })

        img.addEventListener('error', () => {
            if (last == true) {
                setImgMemory(board);
                setPreloaded(resol)
            }

            if (resols.indexOf(resol) != resols.length-1) {
                loadAllFramesImg(path, block, floor, 0, resols[resols.indexOf(resol)+1], last, board)
            }
        });
    }

    // const loadHdForOneFrame = () => { // Fonction pour charger l'ensemble des hd pour une frame donnee
    // }

    const loadCustomProject = (project, callBack) => {
        // if (!Object.keys(imgMemory).includes(project)) {
        //     const board = {"Aerien" : {
        //         "450" : [],
        //         "720" : []
        //     }}
        //     for (let i = 0; i < 90 ; i++) {
        //         const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
        //         const img = new Image();
        //         img.src = `/d3d/${programme}/3D/maquette/${project}/Aerien/jpg-156/${strIndex}.jpg`;
        //         board["Aerien"]["156"].push(img);
        //     }
        //     for (let i = 0; i < 90 ; i++) {
        //         const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
        //         const img = new Image();
        //         img.src = `/d3d/${programme}/3D/maquette/${project}/Aerien/jpg-450/${strIndex}.jpg`;
        //         board["Aerien"]["450"].push(img);
        //     }
        //     for (let i = 0; i < 90 ; i++) {
        //         const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
        //         const img = new Image();
        //         img.src = `/d3d/${programme}/3D/maquette/${project}/Aerien/jpg-720/${strIndex}.jpg`;
        //         board["Aerien"]["720"].push(img);
        //     }
        //     setImgMemory(i => {return ({...i, [project] : board})})
        //     callBack()
        // } else {
        //     callBack()
        // }
    }

    // useEffect(() => {
    //     if (programme !== null) {
    //         fetch(`/d3d/${programme}/3D/media.json`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setDatas(data);
    //         }).catch((err) => {
    //             console.log("Erreur de fetch : ", err);
    //         }) 
    //     }
    // }, [programme])

    // useEffect(() => {

    //     if (programme !== null && datas !== null) {

    //         const toLoad = []
    //         const blocks = Object.keys(datas);

    //         // preparation de la liste de chargement
    //         for (let block of blocks) {
    //             if (datas[block]["Aerien"] != undefined) {
    //                 toLoad.push([block, 'Aerien', 90])
    //             }
    //             if (datas[block]["Pieton"] != undefined) {
    //                 toLoad.push([block, 'Pieton', 90])
    //             }
    //         }

    //         const board = {}

    //         for (let load of toLoad) {
    //             const isLast = load == toLoad[toLoad.length-1];
    //             loadAllFramesImg(`/d3d/${programme}/3D/maquette/${load[0]}/${load[1]}/jpg-`, load[0], load[1], 0, 156, isLast, board);                
    //         }
    //     }
    // }, [programme, datas])

    // useEffect(() => {
    //     if (datas != null) {
    //         loadHdForOneFrame();
    //     }
    // }, [datas, frame])

    return [preloaded, imgMemory, loadCustomProject];
}

export default usePreloader
