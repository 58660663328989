// ////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du toolTip d'information du lot survole dans
// la maquette 3D.
// ////////////////////////////////////////////////////////////////////////
// Creation : 22/04/2022 CGR
// Update : 08/09/2022  -> CGR   : ajout aux favoris
// ////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////
// Imports
import React, {useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { navigationContext } from '../../../contexts';
// import SvgIcon from './../../svg/svg';
// import { domain, programmeNameContext, returnToContext, favorisContext, noHeaderContext } from './../../../contexts';


// ////////////////////////////////////////////////////////////////////////
// Composant react principal pour la vignette de survol de zone
const PlayerToolTip = ({programme, name, type, x, y, setBlock, plusAction = () => {}}) => {

    // Infos ////////////////////////////////
    // name : nom du lot -> str
    // x, y : position de la vignette -> int
    // //////////////////////////////////////

    // Properties
    const [lot , setLot] = useState({
        type : "",
        statut : "",
        surface : 0,
    });
    const [displayDetailLot, setDisplayDetailLot] = useState(false);



    // Render
    return (
        <navigationContext.Consumer>
            {programme => programme !== null &&  
                <div 
                    id = "lotHover" 
                    style = {{
                        top : y, 
                        left : x - 10,
                        cursor : (displayDetailLot && lot.display === "Grisés" || lot.display === "Masqués" || (lot.display == "Grisés sans accès au détail" && lot.statut === "Disponible")) ? "pointer" : "default",
                    }}
                >
                    <div id = "lotHover-container" onClick={() => {
                        setBlock(name);
                        plusAction();
                    }}>
                        {type !== "textOnly" &&
                            <div id = "lotHover-base">
                                <div id = 'lotHover-center'></div>
                            </div>
                        }

                    </div>

                    <p id = 'lotHover-text' >{name}</p>
                </div>
        }</navigationContext.Consumer>
    )
}

// ////////////////////////////////////////////////////////////////////////
// Export
export default PlayerToolTip;
