// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la fiche projet des themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 26/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems

// Imports contexts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';



// /////////////////////////////////////////////////////////////
// Components

const VideoViewer = ({d3d, url, setVideoViewer}) => {
  return <div id="movieViewer">
    <div id="closeViewer" onClick={() => {setVideoViewer(null)}}>
      <FontAwesomeIcon icon = {faClose} size={"xl"}/>
    </div>
    {url.type === "youtube" && 
      <iframe 
        src={`${url.lien}${url.autoplay ? "?autoplay=1&mute=1" : ""}`}>
      </iframe>
    }
    {url.type === "vimeo" &&
        <iframe 
          src={`${url.lien}`}>
        </iframe>
    }
    {url.type === "dailymotion" &&
        <iframe 
          src={`${url.lien}${url.autoplay ? "?autoplay=1&mute=1" : ""}`}>
        </iframe>
    }
    {url.type === "local" && 
      <video controls>
        <source src={`/d3d/${d3d}/projets/videos/${url.lien}`} type="video/mp4" />
      </video>
    }
  </div>
}

// /////////////////////////////////////////////////////////////
// Export
export default VideoViewer;
  