// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la fiche projet des themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 26/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import { useState, useEffect, useContext, useRef, useCallback } from "react";
// Imports contexts
import { navigationContext } from '../../contexts';
import Player from '../player/player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import SvgIcon from "./../svg/svg";
import PdfViewer from "./viewers/pdfViewer";
import VideoViewer from "./viewers/videoViewer";
import Galerie from "./viewers/galerie";
import BeforeAfterViewer from "./viewers/beforeAfterViewer";
import useMobile from "../../globalHooks/useMobile";


// /////////////////////////////////////////////////////////////
// Components


const InfoLine = ({ label, value }) => {
  return (
    <div className="infoLine">
      <p className="infoLineLabel">{label}</p>
      <p className="infoLineValue">{value}</p>
    </div>
  )
}

const FicheProjet = ({ setRef = null }) => {

  const { projet } = useParams();
  const { d3d, ficheProjet, setFicheProjet, setSelectedPoi, loadCustomProject, projects, imgsMemory } = useContext(navigationContext);
  const [surfaces, setSurface] = useState(null);
  const [currentPrj, setCurrentPrj] = useState(null);
  const [maitreOuvr, setMaitreOuvr] = useState(null);
  const [maitreOeuvre, setMaitreOeuvre] = useState(null);
  const [phasage, setPhasage] = useState(null);
  const [phasageColor, setPhasageColor] = useState("done");
  const [phasageColorCustom, setPhasageColorCustom] = useState(null);
  const [typologie, setTypologie] = useState(null);
  const [labels, setLabels] = useState(null);
  const [links, setLinks] = useState(null);
  const [pdfs, setPdfs] = useState(null);
  const [movies, setMovies] = useState(null);
  const [beforeAfter, setBeforeAfter] = useState(null);
  const [text, setText] = useState("");
  const content = useRef();
  const [nameToUse, setNameToUse] = useState(null);
  const [currentViewer, setCurrentViewer] = useState(null);
  const [images, setImages] = useState([]);
  const [playerValid, setPlayerValid] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(null);
  const [videoViewer, setVideoViewer] = useState(null);
  const [bfViewer, setBfViewer] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isTactil, setIsTactil] = useState(false)
  const linkRef = useRef();
  const [selectedView, setSelectedView] = useState(null);

  const toggleView = (view) => {
    setSelectedView(view);
  };

  const close = () => {
    setNameToUse(null);
    setSelectedPoi(null);
    setFicheProjet(null);
    setText("");
  }

  useEffect(() => {

    const onScreenResize = (e) => {
      setCurrentWidth(e.target.innerWidth);
    }

    window.addEventListener('resize', onScreenResize);
    setCurrentWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', onScreenResize);
    }
  }, [])

  useEffect(() => {

    if (projects && ficheProjet) {

      if (projet) {
        if (Object.keys(projects).includes(projet)) {
          setNameToUse(projects[projet].name)
        }
      } else {
        setNameToUse(ficheProjet);
      }
    }
  }, [projects, ficheProjet])

  useEffect(() => {

    if (projects && nameToUse) {

      if (Object.values(projects).map(prj => prj.name).includes(nameToUse)) {

        const currPrj = Object.entries(projects).filter(prj => prj[1].name == nameToUse)[0];
        setCurrentPrj(currPrj[0]);

        //
        if (Object.keys(currPrj[1]).includes("surfaces")) {
          setSurface(currPrj[1].surfaces)
        } else { setSurface(null) }
        //
        if (Object.keys(currPrj[1]).includes("maitre d'ouvrage")) {
          setMaitreOuvr(currPrj[1]["maitre d'ouvrage"])
        } else { setSurface(null) }
        //
        if (Object.keys(currPrj[1]).includes("maitre d'oeuvre")) {
          setMaitreOeuvre(currPrj[1]["maitre d'oeuvre"])
        } else { setMaitreOeuvre(null) }
        //
        if (Object.keys(currPrj[1]).includes("phasage")) {
          setPhasage(currPrj[1]["phasage"])
        } else { setPhasage(null) }
        //
        if (Object.keys(currPrj[1]).includes("typologie")) {
          setTypologie(currPrj[1]["typologie"])
        } else { setTypologie(null) }
        //
        if (Object.keys(currPrj[1]).includes("description")) {
          setText(currPrj[1]["description"])
        } else { setText(null) }
        // 
        if (Object.keys(currPrj[1]).includes("images")) {
          setImages(currPrj[1]["images"]);
        } else { setImages(null) }
        // 
        if (Object.keys(currPrj[1]).includes("label")) {
          setLabels(currPrj[1]["label"]);
        } else { setLabels(null) }
        // 
        if (Object.keys(currPrj[1]).includes("pdfs")) {
          setPdfs(currPrj[1]["pdfs"]);
        } else { setPdfs(null); }
        // 
        if (Object.keys(currPrj[1]).includes("lien externe")) {
          setLinks(currPrj[1]["lien externe"]);
        } else { setLinks(null); }
        // 
        if (Object.keys(currPrj[1]).includes("movies")) {
          setMovies(currPrj[1]["movies"]);
        } else { setMovies(null); }
        // 
        if (Object.keys(currPrj[1]).includes("before-after")) {
          setBeforeAfter(currPrj[1]["before-after"]);
        } else { setBeforeAfter(null); }

        if (Object.keys(currPrj[1]).includes("maquette")) {
          setPlayerValid(currPrj[1]["maquette"]);
        } else { setPlayerValid(false); }


        if (phasage === "En travaux") {
          setPhasageColor("inProgress");
        } else if (phasage === "A venir") {
          setPhasageColor("comingSoon");
        } else if (phasage === "Réalisé") {
          setPhasageColor("done");
        } else {
          setPhasageColorCustom(currPrj[1]["couleurPhasage"]);
        }

      }
    }
  }, [projects, nameToUse, phasage])

  useEffect(() => {
    if (text !== "") {
      content.current.innerHTML = text;

      setIsTactil(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
    }
  }, [text])


  useEffect(() => {
    if (!currentViewer) {
      if (playerValid) {
        setCurrentViewer("Maquette");
      } else if (images && images.length !== 0) {
        setCurrentViewer("Galerie");
      }
    } else if (currentViewer === "Galerie") {
      if (!images || images.length === 0) {
        if (playerValid) {
          setCurrentViewer("Maquette");
        } else {
          setCurrentViewer(null);
        }
      }
    }
    else if (currentViewer === "Maquette") {
      if (!playerValid) {
        if (images && images.length !== 0) {
          setCurrentViewer("Galerie")
        } else {
          setCurrentViewer(null);
        }
      }
    }
  }, [images, currentPrj, ficheProjet, currentViewer]);

  const getDefaultView = useCallback(() => {
    if (playerValid) return "Maquette";
    if (images && images.length !== 0) return "Galerie";
    if (text) return "Description";
    return null;
  }, [playerValid, images, text]);

  const getUpdatedViewForMaquette = useCallback(() => {
    if (playerValid) return;
    if (images && images.length !== 0) return "Galerie";
    if (text) return "Description";
    return null;
  }, [playerValid, images, text]);

  const getUpdatedViewForGalerie = useCallback(() => {
    if (images && images.length !== 0) return;
    if (playerValid) return "Maquette";
    if (text) return "Description";
    return null;
  }, [playerValid, images, text]);

  const getUpdatedViewForDescription = useCallback(() => {
    if (text) return;
    if (images && images.length !== 0) return "Galerie";
    if (playerValid) return "Maquette";
    return null;
  }, [playerValid, images, text]);

  useEffect(() => {
    if (!selectedView) {
      setSelectedView(getDefaultView());
    } else {
      let newView;
      switch (selectedView) {
        case "Maquette":
          newView = getUpdatedViewForMaquette();
          break;
        case "Galerie":
          newView = getUpdatedViewForGalerie();
          break;
        case "Description":
          newView = getUpdatedViewForDescription();
          break;
        default:
          newView = null;
      }
      if (newView !== undefined) setSelectedView(newView);
    }
  }, [getDefaultView, getUpdatedViewForMaquette, getUpdatedViewForGalerie, getUpdatedViewForDescription, selectedView]);


  return (
    <navigationContext.Consumer>
      {datas => datas.ficheProjet ?
        <div id='ficheProjet'
          ref={datas.ficheProjetRef}
          onClick={() => {
            datas.ficheProjetRef.current.className = ""
            if (linkRef.current) {
              for (const ch of linkRef.current.children) {
                ch.classList.remove("hovered")
              }
            }
          }
          }>

          <div id="ficheProjetMedias">
            <div id='closeBtn' onClick={close}>
              <FontAwesomeIcon icon={faClose} color={"#2c2c2c"} size={"xl"}></FontAwesomeIcon>
            </div>
            {(window.innerWidth > 992) && (
              (playerValid || (images && images.length > 0)) && (
                <div id='ficheProjetMedias'>
                  {currentViewer === "Maquette" ? (
                    (playerValid && currentPrj) ? (
                      <Player
                        programme={d3d}
                        navUrl={`${currentPrj}/Aerien`}
                        floor='Aerien'
                        focale={25}
                        onlyImages={true}
                        forcedBlock={`${currentPrj}`}
                        boussole={false}
                        numFrames={Object.keys(imgsMemory).includes(currentPrj) ? 90 : 1}
                        resolutions={[720, 450, 156]}
                      />
                    ) : (
                      <div id="miniPlayerLoading">Chargement des images</div>
                    )
                  ) : (
                    images && <Galerie images={images} />
                  )}

                </div>

              )
            )}

            {(window.innerWidth <= 992) && ((playerValid || (images && images.length > 0)) && (
              <>
                {selectedView === "Maquette" ? (
                  (playerValid && currentPrj) ? (
                    <Player
                      programme={d3d}
                      navUrl={`${currentPrj}/Aerien`}
                      floor='Aerien'
                      focale={25}
                      onlyImages={true}
                      forcedBlock={`${currentPrj}`}
                      boussole={false}
                      numFrames={Object.keys(imgsMemory).includes(currentPrj) ? 90 : 1}
                      resolutions={[720, 450, 156]}
                    />
                  ) : (
                    <div id="miniPlayerLoading">Chargement des images</div>
                  )
                ) : selectedView === "Galerie" ? (
                  images && <Galerie images={images} />
                ) : selectedView === "Description" ? (
                  text && <div id="description-content" ref={content}>
                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                  </div>
                ) : null}
              </>
            )
            )}


          </div>


          <div id="ficheProjetContainerDesc">
            <div id="ficheProjetTopDiv">
              <div id="ficheProjetTitle">
                {<p id="nameToUse" >{nameToUse}</p>}
              </div>
              <div id="ficheProjetPhasage">
                {phasage && <p id="fichePhasage" className={phasageColor} style={phasageColorCustom ? {
                  backgroundColor: phasageColorCustom
                } : null} >{phasage}</p>}
              </div>
              <div id="ficheProjetContainerToggle">
                {(images && images.length !== 0 && playerValid) &&

                  <div id="ficheProjetToggle" onClick={() => { currentViewer === "Maquette" ? setCurrentViewer("Galerie") : setCurrentViewer("Maquette") }}>
                    <p>Maquette</p>
                    <p>Galerie</p>
                    <div id="toggleCurrent" className={`${currentViewer}`}>{currentViewer}</div>
                  </div>
                }
              </div>
            </div>
            <div id="ficheProjetContainerContent">
              <div id='ficheProjetDatas'>
                {typologie || maitreOeuvre || maitreOeuvre || surfaces || labels ? <div id='ficheProjetColumnInfos'>
                  <div className='datasSubline'>
                    {typologie && <InfoLine label="Typologie" value={typologie.filter((t) => t !== "Mixte").map(t => <span key={t}>{t}</span>)}></InfoLine>}
                    {maitreOuvr && <InfoLine label="Maîtrise d'ouvrage" value={`${maitreOuvr}`}></InfoLine>}
                    {maitreOeuvre && <InfoLine label="Maîtrise d'oeuvre" value={maitreOeuvre.map(m => <span>{m}</span>)}></InfoLine>}
                    {currentWidth < 1700 && <>
                      {surfaces && <InfoLine label="Superficie" value={`${surfaces} m²`}></InfoLine>}
                      {labels && <InfoLine label="Labels" value={labels.map(l => <span key={l}>{l}</span>)}></InfoLine>}
                    </>}
                  </div>
                  {currentWidth >= 1700 &&
                    <div className='datasSubline'>
                      {surfaces && <InfoLine label="Superficie" value={`${surfaces} m²`}></InfoLine>}
                      {labels && <InfoLine label="Labels" value={labels.map(l => <span key={l}>{l}</span>)}></InfoLine>}
                    </div>
                  }
                </div>
                  : null}
                <div
                  id='ficheProjetColumnText'
                  className={`${!isScrolledToBottom ? "scrollable" : "scrollEnd"} ${isTactil ? "scrolled" : ""}`}
                >
                  <p id="descriptionTilte">Description</p>
                  <div id='ficheProjetText' ref={content} onScroll={(e) => {
                    setIsScrolledToBottom(e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop + 1);
                  }}>
                  </div>
                  {!isScrolledToBottom &&
                    <div id="scrollIndicator">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div id='ficheProjetLinks' ref={linkRef}>
              {window.innerWidth <= 992 ? (
                <div id="toggleButtons">
                  {playerValid && (
                    <div
                      onClick={() => toggleView("Maquette")}
                      className={`button-nav-responsive ${selectedView === "Maquette" ? "activ" : ""}`}
                    >
                      <SvgIcon icon={"picto-maquette"} />
                    </div>
                  )}
                  {images && (
                    <div
                      onClick={() => toggleView("Galerie")}
                      className={`button-nav-responsive ${selectedView === "Galerie" ? "activ" : ""}`}
                    >
                      <SvgIcon icon={"picto-galerie"} />
                    </div>
                  )}
                  {text && (
                    <div
                      onClick={() => toggleView("Description")}
                      className={`button-nav-responsive ${selectedView === "Description" ? "activ" : ""}`}
                    >
                      <SvgIcon icon={"picto-description"} />
                    </div>
                  )}
                </div>
              ) : null}

              {beforeAfter &&
                beforeAfter.map(bf => <div key={`bf-${bf.title}`} className="link-btn" onClick={(e) => {
                  e.stopPropagation();
                  if (e.nativeEvent.pointerType === "touch") {
                    // on commence par vider le reste
                    if (linkRef.current) {
                      for (const ch of linkRef.current.children) {
                        if (ch !== e.target) {
                          ch.classList.remove("hovered")
                        }
                      }
                    }
                    if (e.target.matches('.hovered')) {
                      e.target.classList.remove('hovered')
                      setBfViewer(<BeforeAfterViewer d3d={d3d} datas={bf} setBfViewer={setBfViewer} />)
                    } else {
                      e.target.classList.add('hovered')
                    }
                  }
                  else {
                    setBfViewer(<BeforeAfterViewer d3d={d3d} datas={bf} setBfViewer={setBfViewer} />)
                  }
                }}>

                  <SvgIcon icon={"picto-avant-apres"} />
                  <div className="link-btn-title">
                    <p>{bf.title}</p>
                  </div>
                </div>)
              }
              {movies &&
                movies.map(movie => <div key={`mov-${movie.lien}`} className="link-btn" onClick={(e) => {
                  e.stopPropagation();

                  if (e.nativeEvent.pointerType === "touch") {

                    // on commence par vider le reste
                    if (linkRef.current) {
                      for (const ch of linkRef.current.children) {
                        if (ch !== e.target) {
                          ch.classList.remove("hovered")
                        }
                      }
                    }
                    if (e.target.matches('.hovered')) {
                      e.target.classList.remove('hovered')
                      setVideoViewer(<VideoViewer d3d={d3d} url={movie} setVideoViewer={setVideoViewer} />)
                    } else {
                      e.target.classList.add('hovered')
                    }
                  }
                  else {
                    setVideoViewer(<VideoViewer d3d={d3d} url={movie} setVideoViewer={setVideoViewer} />)
                  }
                }}>
                  <SvgIcon icon={"picto-video"} />
                  <div className="link-btn-title">
                    <p >{movie.title}</p>
                  </div>
                </div>)
              }
              {pdfs &&
                pdfs.map(pdf => <div key={`pdf-${pdf}`} className="link-btn" onClick={(e) => {
                  e.stopPropagation();

                  if (e.nativeEvent.pointerType === "touch") {

                    // on commence par vider le reste
                    if (linkRef.current) {
                      for (const ch of linkRef.current.children) {
                        if (ch !== e.target) {
                          ch.classList.remove("hovered")
                        }
                      }
                    }
                    if (e.target.matches('.hovered')) {
                      e.target.classList.remove('hovered')
                      setPdfViewer(<PdfViewer url={`/d3d/${d3d}/projets/pdfs/${pdf}`} setPdfViewer={setPdfViewer} />)
                    } else {
                      e.target.classList.add('hovered')
                    }
                  }
                  else {
                    setPdfViewer(<PdfViewer url={`/d3d/${d3d}/projets/pdfs/${pdf}`} setPdfViewer={setPdfViewer} />)
                  }
                }}>
                  <SvgIcon icon={"picto-pdf"} />
                  <div className="link-btn-title">
                    <p>{pdf}</p>
                  </div>
                </div>)
              }
              {links &&
                links.map(link => <div key={link.title} className="link-btn" href={link.lien} target="_blank" onClick={(e) => {
                  e.stopPropagation();
                  if (e.nativeEvent.pointerType === "touch") {

                    // on commence par vider le reste
                    if (linkRef.current) {
                      for (const ch of linkRef.current.children) {
                        if (ch !== e.target) {
                          ch.classList.remove("hovered")
                        }
                      }
                    }
                    if (e.target.matches('.hovered')) {
                      e.target.classList.remove('hovered')
                      window.open(link.lien, "_blank")
                    } else {
                      e.target.classList.add('hovered')
                    }
                  }
                  else {
                    window.open(link.lien, "_blank")
                  }
                }}>
                  <SvgIcon icon={"picto-web"} />
                  <div className="link-btn-title">
                    <p>{link.title}</p>
                  </div>
                </div>)
              }
            </div>
            {pdfViewer}
            {videoViewer}
            {bfViewer}
          </div>
        </div>
        :
        <></>
      }
    </navigationContext.Consumer>
  )
}


// /////////////////////////////////////////////////////////////
// Export
export default FicheProjet;