import React, { useState, useEffect, useContext } from 'react';
import Button from '../../components/button/button';
import { noHeaderContext, navigationContext, iOS } from '../../contexts';

const FullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992); // Check if the window width is less than or equal to 992 pixels
    const noHeader = useContext(noHeaderContext);

    useEffect(() => {
        const checkFullScreen = () => {
            const domElement = document.getElementById("root");
            const wx = domElement.clientWidth;
            const wy = domElement.clientHeight;

            if (window.screen.width === wx && window.screen.height === wy) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }

            // Show or hide mobile-test div based on fullscreen state and window width
            toggleMobileTestVisibility(!isFullScreen && isMobile);
        };

        window.addEventListener("resize", checkFullScreen);

        return () => {
            window.removeEventListener("resize", checkFullScreen);
        };
    }, [isFullScreen, isMobile]);

    const toggleFullScreen = () => {
        const domElement = document.getElementById("root");

        if (!isFullScreen) {
            if (domElement.requestFullscreen) {
                domElement.requestFullscreen();
            } else if (domElement.mozRequestFullScreen) {
                domElement.mozRequestFullScreen();
            } else if (domElement.webkitRequestFullscreen) {
                domElement.webkitRequestFullscreen();
            } else if (domElement.msRequestFullscreen) {
                domElement.msRequestFullscreen();
            }
            setIsFullScreen(true);
            toggleMobileTestVisibility(false);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullScreen(false);
            toggleMobileTestVisibility(true);
        }
    };

    const toggleMobileTestVisibility = (show) => {
        const mobileTestDiv = document.getElementById('mobile-test');
        mobileTestDiv.style.display = show ? 'flex' : 'none';
    };


    return (
        <navigationContext.Consumer>
            {datas => (datas != null) &&
                <div id='fullScreen' className={noHeader ? 'noHeader' : ''}>
                    <Button
                        id='btn-fullScreen'
                        icon={'picto-fullscreen'}
                        text='plein écran'
                        state={isFullScreen}
                        action={() => toggleFullScreen()}
                        displayText={datas.displayText}
                    />
                </div>
            }
        </navigationContext.Consumer>
    );

};

export default FullScreen;
