// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons de style allongé
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 17/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';
import SvgIcon from '../svg/svg';

// /////////////////////////////////////////////////////////////
// Component
const ButtonLong = ({text, state = false, action = () => {}, icon = "picto-bus"}) => {
    return (
      <div 
        className={`buttonLong ${state ? "activ" : ""}`}
        onClick={action}
      >
        {icon && <SvgIcon icon = {icon}/>}
        <p>{text}</p>
      </div>
    )
}

  
// /////////////////////////////////////////////////////////////
// Export
export default ButtonLong;
