// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page Presenter/Filtres
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 24/11/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Imports contexts
import { navigationContext } from './../../../contexts'
// Imports components
import LinkedButtonLong from '../../../components/buttonLong/linkedButtonLong';



// /////////////////////////////////////////////////////////////
// Component
const Filtres = () => {

  // Properties
  const { d3d, d3dDatas, display, setSearchResult, projects } = useContext(navigationContext);
  const { filtre } = useParams();
  const [filtres, setFiltres] = useState(null);
  const [y, setY] = useState(0);

  //Effects
  useEffect(() => { //
    if (d3dDatas) {
      setFiltres(
        d3dDatas.filtres.map(
          filtreDatas => <LinkedButtonLong
            key={filtreDatas[0]}
            text={filtreDatas[0]}
            link={`/${d3d}/${display}/filtres/${filtreDatas[0]}`}
            state={filtre == filtreDatas[0]}
            icon={filtreDatas[1]}
          />
        )
      )

      setTimeout(() => {
        const parentBtn = document.getElementById("btn-filtres");
        if (parentBtn) {
          setY(parentBtn.parentElement.parentElement.offsetTop + parentBtn.offsetTop - (d3dDatas.filtres.length / 2 * 49));
        }
      }, 10)


      if (projects && filtre) {
        setSearchResult(Object.entries(projects).filter(e => e[1].typologie && e[1].typologie.includes(filtre)).map(e => e[1].name));
      }

    }
  }, [projects, d3dDatas, filtre])


  // Render
  return (
    <div id="filtres">
      <div id="filtresSelection" style={{ top: `${y}px` }}>
        {filtres}
      </div>

    </div>
  )
}



// /////////////////////////////////////////////////////////////
// Export
export default Filtres;
