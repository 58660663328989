// SVG - Generiques
import { ReactComponent as Activites } from './../svg/files/picto-activites.svg';
import { ReactComponent as Activites1 } from './../svg/files/picto-activites-1.svg';
import { ReactComponent as ActiviteSolid} from './../svg/files/picto-activites-solid.svg';
import { ReactComponent as ActiviteSolid1} from './../svg/files/picto-activites-1-solid.svg'; 
import { ReactComponent as Administration } from './../svg/files/picto-administration.svg';
import { ReactComponent as Attractivite } from './../svg/files/picto-attractivite.svg'; 
import { ReactComponent as Autolib } from './../svg/files/picto-autolib.svg';
import { ReactComponent as Autoroute } from './../svg/files/picto-autoroute.svg';
import { ReactComponent as AvantApres } from './../svg/files/picto-avant-apres.svg';
import { ReactComponent as Avion } from './../svg/files/picto-avion.svg';
import { ReactComponent as AvionSolid } from './../svg/files/picto-avion-solid.svg';
import { ReactComponent as Bas } from './../svg/files/picto-bas.svg';
import { ReactComponent as Boussole } from './../svg/files/picto-boussole.svg';
import { ReactComponent as Bureaux } from './../svg/files/picto-bureaux.svg';
import { ReactComponent as Bus } from './../svg/files/picto-bus.svg';
import { ReactComponent as BureauxSolid } from './../svg/files/picto-bureaux-solid.svg';
import { ReactComponent as Resume } from './../svg/files/picto-carresoie-resume.svg';
import { ReactComponent as Carte } from './../svg/files/picto-cartes.svg';
import { ReactComponent as Commerces } from './../svg/files/picto-commerces.svg';
import { ReactComponent as Commerces1 } from './../svg/files/picto-commerces-1.svg';
import { ReactComponent as CommercesSolid } from './../svg/files/picto-commerces-solid.svg';
import { ReactComponent as Creche } from './../svg/files/picto-creche.svg';
import { ReactComponent as Culture } from './../svg/files/picto-culture.svg';
import { ReactComponent as Description } from './../svg/files/picto-description.svg';
import { ReactComponent as Down } from './../svg/files/picto-down.svg';
import { ReactComponent as Droite } from './../svg/files/picto-droite.svg';
import { ReactComponent as Ecole } from './../svg/files/picto-ecole.svg';
import { ReactComponent as Education } from './../svg/files/picto-education.svg';
import { ReactComponent as EducationSolid } from './../svg/files/picto-education-solid.svg';
import { ReactComponent as Envoyer } from './../svg/files/picto-envoyer.svg';
import { ReactComponent as Equipements } from './../svg/files/picto-equipements.svg';
import { ReactComponent as EquipementsSolid} from './../svg/files/picto-equipements-solid.svg';
import { ReactComponent as EspacesPublics } from './../svg/files/picto-espaces-publics.svg';
import { ReactComponent as Evolutives } from './../svg/files/picto-evolutives.svg';
import { ReactComponent as Europe } from './../svg/files/picto-europe.svg';
import { ReactComponent as France } from './../svg/files/picto-france.svg';
import { ReactComponent as FranceSolid } from './../svg/files/picto-france-solid.svg';
import { ReactComponent as Favoris } from './../svg/files/picto-favoris.svg';
import { ReactComponent as Favoris1 } from './../svg/files/picto-favoris-1.svg';
import { ReactComponent as FavorisSolid } from './../svg/files/picto-favoris-solid.svg';
import { ReactComponent as FavorisSolid1 } from './../svg/files/picto-favoris-1-solid.svg';
import { ReactComponent as France2 } from './../svg/files/picto-france-2.svg';
import { ReactComponent as FullScreen } from './../svg/files/picto-fullscreen.svg';
import { ReactComponent as Galerie } from './../svg/files/picto-galerie.svg';
import { ReactComponent as Gauche } from './../svg/files/picto-gauche.svg';
import { ReactComponent as Haut } from './../svg/files/picto-haut.svg';
import { ReactComponent as Home } from './../svg/files/picto-home.svg';
import { ReactComponent as Hotel } from './../svg/files/picto-hotel.svg';
import { ReactComponent as Infra } from './../svg/files/picto-infrastructure-1.svg';
import { ReactComponent as Justice } from './../svg/files/picto-justice.svg';
import { ReactComponent as Left } from './../svg/files/picto-left.svg';
import { ReactComponent as Localisation } from './../svg/files/picto-localisation.svg';
import { ReactComponent as Lion } from './../svg/files/picto-lion.svg';
import { ReactComponent as Logement } from './../svg/files/picto-logements.svg';
import { ReactComponent as Logement1 } from './../svg/files/picto-logements1.svg';
import { ReactComponent as Logement2 } from './../svg/files/picto-logements2.svg';
import { ReactComponent as Logement3 } from './../svg/files/picto-logements3.svg';
import { ReactComponent as Loisirs } from './../svg/files/picto-loisirs.svg';
import { ReactComponent as Lycee } from './../svg/files/picto-lycee.svg';
import { ReactComponent as Map } from './../svg/files/picto-map.svg';
import { ReactComponent as Maquette } from './../svg/files/picto-maquette.svg';
import { ReactComponent as Metro } from './../svg/files/picto-metro.svg';
import { ReactComponent as Metro2 } from './../svg/files/picto-metro-2.svg';
import { ReactComponent as Mixte } from './../svg/files/picto-mixte.svg';
import { ReactComponent as Mobilites } from './../svg/files/picto-mobilites.svg';
import { ReactComponent as Panorama } from './../svg/files/picto-panorama.svg';
import { ReactComponent as Parc } from './../svg/files/picto-parc.svg';
import { ReactComponent as Paris } from './../svg/files/picto-paris.svg';
import { ReactComponent as Paris1 } from './../svg/files/picto-paris1.svg';
import { ReactComponent as Parking1 } from './../svg/files/picto-parking-1.svg';
import { ReactComponent as Parking2 } from './../svg/files/picto-parking-2.svg';
import { ReactComponent as Partager } from './../svg/files/picto-partager.svg';
import { ReactComponent as Partager1 } from './../svg/files/picto-partager-1.svg';
import { ReactComponent as Patrimoine } from './../svg/files/picto-patrimoine.svg';
import { ReactComponent as Patrimoine1 } from './../svg/files/picto-patrimoine-1.svg';
import { ReactComponent as Patrimoine2 } from './../svg/files/picto-patrimoine-2.svg';
import { ReactComponent as Pdf } from './../svg/files/picto-pdf.svg';
import { ReactComponent as Pin } from './../svg/files/picto-pin.svg';
import { ReactComponent as Pin1 } from './../svg/files/picto-pin1.svg';
import { ReactComponent as Plus } from './../svg/files/picto-plus.svg';
import { ReactComponent as Poubelle } from './../svg/files/picto-poubelle.svg';
import { ReactComponent as Projet } from './../svg/files/picto-projets.svg';
import { ReactComponent as Quartier1 } from './../svg/files/picto-quartier-1.svg';
import { ReactComponent as QuartierSolid } from './../svg/files/picto-quartier-1-solid.svg';
import { ReactComponent as Recherche } from './../svg/files/picto-recherche.svg';
import { ReactComponent as Rechercher } from './../svg/files/picto-rechercher.svg';
import { ReactComponent as Region } from './../svg/files/picto-region.svg';
import { ReactComponent as Requalifiees } from './../svg/files/picto-requalifiees.svg';
import { ReactComponent as Restaurant } from './../svg/files/picto-restaurant.svg';
import { ReactComponent as Right } from './../svg/files/picto-right.svg';
import { ReactComponent as Sciences } from './../svg/files/picto-science.svg';
import { ReactComponent as Services } from './../svg/files/picto-services.svg';
import { ReactComponent as Settings1 } from './../svg/files/picto-settings-1.svg';
import { ReactComponent as Settings2 } from './../svg/files/picto-settings-2.svg';
import { ReactComponent as Shop } from './../svg/files/picto-shop.svg';
import { ReactComponent as Sport } from './../svg/files/picto-sport.svg';
import { ReactComponent as Sports1 } from './../svg/files/picto-sport-1.svg';
import { ReactComponent as Supprimer } from './../svg/files/picto-supprimer.svg';
import { ReactComponent as Synthese } from './../svg/files/picto-synthese.svg';
import { ReactComponent as Telecharger } from './../svg/files/picto-telecharger.svg';
import { ReactComponent as Tous } from './../svg/files/picto-tous.svg';
import { ReactComponent as Tous1 } from './../svg/files/picto-tous1.svg';
import { ReactComponent as Tous2 } from './../svg/files/picto-tous2.svg';
import { ReactComponent as Themes } from './../svg/files/picto-themes.svg';
import { ReactComponent as Train } from './../svg/files/picto-train.svg';
import { ReactComponent as Tram } from './../svg/files/picto-tram.svg';
import { ReactComponent as Tramway } from './../svg/files/picto-tramway.svg';
import { ReactComponent as TrameVerte } from './../svg/files/picto-trame-verte.svg';
import { ReactComponent as Up } from './../svg/files/picto-up.svg';
import { ReactComponent as Velo } from './../svg/files/picto-velo.svg';
import { ReactComponent as Video } from './../svg/files/picto-video.svg';
import { ReactComponent as Video1 } from './../svg/files/picto-video-1.svg';
import { ReactComponent as Ville } from './../svg/files/picto-ville.svg';
import { ReactComponent as Voiture } from './../svg/files/picto-voiture.svg';
import { ReactComponent as VoitureElectrique } from './../svg/files/picto-voitureelectrique.svg';
import { ReactComponent as Web } from './../svg/files/picto-web.svg';

let svgHelper = {};

//TODO: Rendre le tout dynamique, éviter de créer systématiquement une ligne par .svg

//TODO: Gérer cas sans icon (éventuellement dans button.js)
svgHelper['']                                       = <Autolib />;
//DESC: Boutons génériques./
svgHelper['picto-activites']                        = <Activites />;
svgHelper['picto-activites-1']                      = <Activites1 />;
svgHelper['picto-activites-solid']                  = <ActiviteSolid />;
svgHelper['picto-activites-1-solid']                = <ActiviteSolid1 />;
svgHelper['picto-administration']                   = <Administration />;
svgHelper['picto-attractivite']                     = <Attractivite />;
svgHelper['picto-autolib']                          = <Autolib />;
svgHelper['picto-autoroute']                        = <Autoroute />;
svgHelper['picto-avant-apres']                      = <AvantApres />;
svgHelper['picto-avion']                            = <Avion />;
svgHelper['picto-avion-solid']                      = <AvionSolid />;
svgHelper['picto-bas']                              = <Bas />;
svgHelper['picto-bureaux']                          = <Bureaux />;
svgHelper['picto-bureaux-solid']                    = <BureauxSolid />;
svgHelper['picto-bus']                              = <Bus />;
svgHelper['picto-boussole']                         = <Boussole />;
svgHelper['picto-carresoie-resume']                 = <Resume />;
svgHelper['picto-cartes']                           = <Carte />;
svgHelper['picto-commerces']                        = <Commerces />;
svgHelper['picto-commerces-1']                      = <Commerces1 />;
svgHelper['picto-commerces-solid']                  = <CommercesSolid />;
svgHelper['picto-culture']                          = <Culture />;
svgHelper['picto-creche']                           = <Creche />;
svgHelper['picto-description']                      = <Description />;
svgHelper['picto-droite']                           = <Droite />;
svgHelper['picto-down']                             = <Down />;
svgHelper['picto-education']                        = <Education />;
svgHelper['picto-education-solid']                  = <EducationSolid />;
svgHelper['picto-ecole']                            = <Ecole />;
svgHelper['picto-envoyer']                          = <Envoyer />;
svgHelper['picto-equipements']                      = <Equipements />;
svgHelper['picto-equipements-solid']                = <EquipementsSolid />;
svgHelper['picto-espaces-publics']                  = <EspacesPublics />;
svgHelper['picto-evolutives']                       = <Evolutives />;
svgHelper['picto-europe']                           = <Europe />;
svgHelper['picto-favoris']                          = <Favoris />;
svgHelper['picto-favoris-1']                        = <Favoris1 />;
svgHelper['picto-favoris-solid']                    = <FavorisSolid />;
svgHelper['picto-favoris-1-solid']                  = <FavorisSolid1 />;
svgHelper['picto-france']                           = <France />;
svgHelper['picto-france-solid']                     = <FranceSolid />;
svgHelper['picto-france-2']                         = <France2 />;
svgHelper['picto-fullscreen']                       = <FullScreen />;
svgHelper['picto-galerie']                          = <Galerie />;
svgHelper['picto-gauche']                           = <Gauche />;
svgHelper['picto-haut']                             = <Haut />;
svgHelper['picto-home']                             = <Home />;
svgHelper['picto-hotel']                            = <Hotel />;
svgHelper['picto-infrastructure-1']                 = <Infra />;
svgHelper['picto-justice']                          = <Justice />;
svgHelper['picto-left']                             = <Left />;
svgHelper['picto-localisation']                     = <Localisation />;
svgHelper['picto-loisirs']                          = <Loisirs />;
svgHelper['picto-lion']                             = <Lion />;
svgHelper['picto-logements']                        = <Logement />;
svgHelper['picto-logements1']                       = <Logement1 />;
svgHelper['picto-logements2']                       = <Logement2 />;
svgHelper['picto-logements3']                       = <Logement3 />;
svgHelper['picto-lycee']                            = <Lycee />;
svgHelper['picto-map']                              = <Map />;
svgHelper['picto-maquette']                         = <Maquette />;
svgHelper['picto-metro']                            = <Metro />;
svgHelper['picto-metro-2']                          = <Metro2 />;
svgHelper['picto-mixte']                            = <Mixte />;
svgHelper['picto-mobilites']                        = <Mobilites />;
svgHelper['picto-panorama']                         = <Panorama />;
svgHelper['picto-parc']                             = <Parc />;
svgHelper['picto-paris']                            = <Paris />;
svgHelper['picto-paris1']                           = <Paris1 />;
svgHelper['picto-parking-1']                        = <Parking1 />;
svgHelper['picto-parking-2']                        = <Parking2 />;
svgHelper['picto-partager']                         = <Partager />;
svgHelper['picto-partager-1']                        = <Partager1 />;
svgHelper['picto-patrimoine']                       = <Patrimoine />;
svgHelper['picto-patrimoine-1']                     = <Patrimoine1 />;
svgHelper['picto-patrimoine-2']                     = <Patrimoine2 />;
svgHelper['picto-pdf']                              = <Pdf />;
svgHelper['picto-pin']                              = <Pin />;
svgHelper['picto-pin1']                             = <Pin1 />;
svgHelper['picto-plus']                             = <Plus />;
svgHelper['picto-poubelle']                         = <Poubelle />;
svgHelper['picto-projets']                          = <Projet />;
svgHelper['picto-quartier-1']                        = <Quartier1 />;
svgHelper['picto-quartier-1-solid']                 = <QuartierSolid />;
svgHelper['picto-recherche']                        = <Recherche />;
svgHelper['picto-rechercher']                       = <Rechercher />;
svgHelper['picto-region']                           = <Region />;
svgHelper['picto-requalifiees']                     = <Requalifiees />;
svgHelper['picto-restaurant']                       = <Restaurant />;
svgHelper['picto-right']                            = <Right />;
svgHelper['picto-science']                          = <Sciences />;
svgHelper['picto-services']                         = <Services />;
svgHelper['picto-settings-1']                        = <Settings1 />;
svgHelper['picto-settings-2']                        = <Settings2 />;
svgHelper['picto-shop']                             = <Shop />;
svgHelper['picto-sport']                            = <Sport />;
svgHelper['picto-sports-1']                         = <Sports1 />;
svgHelper['picto-supprimer']                        = <Supprimer />;
svgHelper['picto-synthese']                         = <Synthese />;
svgHelper['picto-telecharger']                      = <Telecharger />;
svgHelper['picto-themes']                           = <Themes />;
svgHelper['picto-tous']                             = <Tous />;
svgHelper['picto-tous1']                            = <Tous1 />;
svgHelper['picto-tous2']                            = <Tous2 />;
svgHelper['picto-train']                            = <Train />;
svgHelper['picto-tram']                             = <Tram />;
svgHelper['picto-trame-verte']                      = <TrameVerte />;
svgHelper['picto-tramway']                          = <Tramway />;
svgHelper['picto-up']                               = <Up />;
svgHelper['picto-velo']                             = <Velo />;
svgHelper['picto-video']                            = <Video />;
svgHelper['picto-video-1']                           = <Video1 />;
svgHelper['picto-ville']                            = <Ville />;
svgHelper['picto-voiture']                          = <Voiture />;
svgHelper['picto-voitureelectrique']                = <VoitureElectrique />;
svgHelper['picto-web']                              = <Web />;

export default svgHelper;