// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des routes D3D
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 18/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Routes, Route, Outlet } from 'react-router-dom';

import { navigationContext } from './../../../contexts';
import Header from '../header';
import Themes from './../../../pages/presenter/themes/themes';
import Projet from './../../../pages/presenter/projet/projet';
import Rechercher from '../../../pages/rechercher/rechercher';
import Cartes from '../../../pages/presenter/cartes/cartes';

import Player from '../../../components/player/player';
import MediaCarte from '../../../components/carte/carte';
import Home from '../../../pages/home/home';
import FicheProjet from '../../../components/ficheProjet/ficheProjet';
import usePreloader from '../../../components/player/hooks/usePreloader';
import Filtres from '../../../pages/presenter/filtres/filtres';
import GlobalStyle from '../../../style/globalStyle';
import AppLoader from '../../../components/appLoader/appLoader';

import { getD3DDatas, getD3DProjects, getD3DManifest, getD3DMedias } from '../../../utils/getD3DDatas';
import FullScreen from '../../fullScreen/fullScreen';
import Credits from '../../credits/credits';



// /////////////////////////////////////////////////////////////
// Component
const PlayerLoader = () => {

    return (
        <div id="playerLoader">
            <p>Chargement en cours...</p>
        </div>
    )
}

const D3DMainRouter = () => {

    // Proprietes
    const { d3dUrl } = useParams(); // parametres de l'url
    const [d3d, setD3d] = useState(null); // district3D actif
    const navigate = useNavigate(); // navigation
    const [currentLvl2, setCurrentLvl2] = useState(""); // etat de page active 2
    const [currentLvl1, setCurrentLvl1] = useState("presenter"); // etat de page active 1
    const [display, setDisplay] = useState("3D"); // etat du toogle 2d/3d
    const [d3dDatas, setD3dDatas] = useState(null); // donnees de d3d
    const [preloader, imgMemory, loadCustomProject] = usePreloader(d3d); // Preloader pour les maquettes
    const [preloaded, setPreloaded] = useState(156);
    const [projects, setProjects] = useState(null);
    const [imgsMemory, setImgsMemory] = useState({});
    const [medias, setMedias] = useState(null);
    const [miniSearch, setMiniSearch] = useState({
        type: "Tous",
        state: "Tous",
        minSurface: null,
        maxSurface: null
    });
    const [searchResult, setSearchResult] = useState([]);
    const [ficheProjet, setFicheProjet] = useState(null);
    const ficheProjetRef = useRef(null);
    const mapRef = useRef(null);
    const [block, setBlock] = useState("Vue générale");
    const [currentMqtLvl0, setCurrentMqtLvl0] = useState("Vue générale");
    const [currentMqtLvl1, setCurrentMqtLvl1] = useState("");
    const [selectedPoi, setSelectedPoi] = useState(null); // gestion de la surbriance des puces
    const currentRotator = useRef();
    const [loadedFrames, setLoadedFrames] = useState(1);
    const loadOnlyOneTime = useRef(false);


    // ///////////////////////////////////////
    // Effects

    useEffect(() => {
        // Recuperation du nom du d3d a partir de l'url
        setD3d(d3dUrl)
    }, [d3dUrl])


    useEffect(() => {
        // Fetch des donnees d3d
        if (d3d) {

            getD3DDatas(d3d).then(
                datas => setD3dDatas(datas)
            ).then(() => {
                getD3DProjects(d3d).then(
                    projets => {
                        setProjects(projets)
                        setSearchResult(Object.keys(projets).map(projet => projets[projet].name))
                    }
                )
            }).finally(() => {
                getD3DMedias(d3d).then(meds => setMedias(meds))
            })

        }
    }, [d3d])


    useEffect(() => {
        // Setting pour offline
        if (d3dDatas && medias) {
            if (!loadOnlyOneTime.current) {
                loadOnlyOneTime.current = true;
                AppLoader(d3d, d3dDatas, medias, preloaded, setPreloaded, loadedFrames, setLoadedFrames, setImgsMemory);
                getD3DManifest(d3d);
            }

        }
    }, [d3d, d3dDatas, medias])


    // useEffect(() => {
    //     setPreloaded(preloader);
    //     setImgsMemory(imgMemory);
    // }, [preloader, imgMemory])


    return (

        <navigationContext.Provider value={{
            d3d: d3d,
            setD3D: setD3d,
            currentLvl1: currentLvl1,
            setCurrentLvl1: setCurrentLvl1,
            currentLvl2: currentLvl2,
            setCurrentLvl2: setCurrentLvl2,
            display: display,
            setDisplay: setDisplay,
            d3dDatas: d3dDatas,
            setD3dDatas: setD3dDatas,
            miniSearch: miniSearch,
            setMiniSearch: setMiniSearch,
            searchResult: searchResult,
            setSearchResult: setSearchResult,
            ficheProjet: ficheProjet,
            setFicheProjet: setFicheProjet,
            ficheProjetRef: ficheProjetRef,
            mapRef: mapRef,
            block: block,
            setBlock: setBlock,
            currentMqtLvl0,
            setCurrentMqtLvl0,
            currentMqtLvl1,
            setCurrentMqtLvl1,
            selectedPoi,
            setSelectedPoi,
            preloaded: preloaded,
            imgsMemory: imgsMemory,
            setImgsMemory: setImgsMemory,
            loadCustomProject: loadCustomProject,
            currentRotator: currentRotator,
            projects: projects,
            setProjects: setProjects,
            medias: medias,
            setMedias: setMedias
        }}
        >

            <GlobalStyle />

            {d3dDatas &&
                <Routes>
                    <Route path='' element={<Header top={d3d} />} />
                    <Route path='*' element={<Header top={d3d} />} />
                    <Route path=':displayUrl' element={<Header top={d3d} />} />
                    <Route path=':displayUrl/:subPage' element={<Header top={d3d} />} />
                    <Route path=':displayUrl/:subPage/:component' element={<Header top={d3d} />} />

                </Routes>

            }


            {d3dDatas &&
                <Routes>
                    <Route path="2D/*">
                        <Route path="" element={<Outlet />} />
                        <Route path="themes">
                            <Route path="" element={<Themes />}></Route>
                            <Route path=":theme" element={<Themes />}></Route>
                        </Route>

                        <Route path="cartes">
                            <Route path="" element={<Cartes />}></Route>
                            <Route path=":carte" element={<Cartes />}></Route>
                        </Route>

                        <Route path="projets">
                            <Route path="" element={<Projet />}></Route>
                            {/* <Route path = ":projet" element = {<FicheProjet close= {() => {navigate(-1)}} player = {true}/>}></Route> */}
                        </Route>
                    </Route>

                    {/* Routes pour explorer */}
                    <Route path="3D/*">
                        <Route path="" element={<Outlet />} />
                        <Route path="filtres">
                            <Route path="" element={<Filtres />}></Route>
                            <Route path=":filtre" element={<Filtres />}></Route>
                        </Route>
                        <Route path="projets/*">
                            <Route path="" element={<Projet />}></Route>
                            <Route path=":projet" element={<FicheProjet close={() => { navigate(-1) }} player={true} />}></Route>
                        </Route>
                        <Route path="rechercher" element={<Rechercher />} />
                    </Route>
                </Routes>
            }

            {d3dDatas &&
                <Routes>
                    <Route path="" element={<Home />}></Route>
                    <Route path="2D/themes/:theme" element={<MediaCarte page="themes" />} />
                    <Route path="2D/cartes/:carte" element={<MediaCarte page="cartes" />} />
                    <Route path="2D/projets/:projet" element={<MediaCarte page="projets" />} />
                    <Route path="2D/projets" element={<MediaCarte page="projets" />} />
                    <Route path="2D/*" element={<MediaCarte />} />
                    <Route path="3D">
                        <Route path="" element={<div id="maquette">
                            {(preloaded && loadedFrames !== 0) ?
                                <Player
                                    programme={d3d}
                                    navUrl={`${block}/Aerien`}
                                    floor='Aerien'
                                    focale={45}
                                    numFrames={loadedFrames}
                                    boussole={true}
                                />
                                :
                                <PlayerLoader />
                            }
                        </div>}></Route>
                        <Route path="*" element={<div id="maquette">
                            {(preloaded && loadedFrames !== 0) ?
                                <Player
                                    programme={d3d}
                                    navUrl={`${block}/Aerien`}
                                    floor='Aerien'
                                    focale={45}
                                    numFrames={loadedFrames}
                                    boussole={true}
                                />
                                :
                                <PlayerLoader />
                            }
                        </div>}>
                        </Route>
                    </Route>
                </Routes>
            }

            {d3dDatas &&
                <Routes>
                    <Route path="*" element={
                        <FicheProjet />
                    }></Route>
                </Routes>
            }

            <div id="mobile-warning">
                <div id="mobile-preview"></div>
                <p>Pour profiter pleinement de l'expérience, tournez votre téléphone et passez en plein écran</p>
            </div>

            <div id='mobile-test'>
                <div id='fullScreen' className={d3dDatas && d3dDatas.noHeader ? 'noHeader' : ''}>
                    <FullScreen />
                </div>
            </div>

            <Credits/>
        </navigationContext.Provider>
        

    )
}



// /////////////////////////////////////////////////////////////
// Export
export default D3DMainRouter;
