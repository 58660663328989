import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';


const Poi = ({ name, color, textIsVisible, state = "", onClick = null, textPuce, icon = null, context, type = ""}) => {

  return (
    <div onClick={onClick} className={`poi-container ${state} ${type === "map" ? "map" : "maquette"}`}>
      <div className={`poi-base ${!textPuce ? "disablePointer" : ""}`} style={{ backgroundColor: color }}>
          {!icon ? 
            <div className='poi-center'>
              {onClick && textPuce &&
                <FontAwesomeIcon icon={faAdd} color={'#282828'} />
              }
            </div> 
              : 
            <div className='poi-center-icon'>
              <img src={`/pictos/${icon}.png`}></img>
            </div>
          }
      </div>
      <p style={{ backgroundColor: color }} className={`poi-text ${!textIsVisible ? "noToolTip" : ""}`}>{name}</p>
    </div>
  );
}

export default Poi;

