// ///////////////////////////////////////////////////////
// Contexts
// ///////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ///////////////////////////////////////////////////////

import React from 'react';

export const parentContext = React.createContext({
    parent : null,
    setParent : parent => {}
});

export const navigationContext = React.createContext({
    d3d : null,
    setD3d : d3d => {},
    currentLvl1 : false,
    setCurrentLvl1 : current => {},
    currentLvl2 : false,
    setCurrentLvl2 : current => {},
    display : "",
    setDisplay : display => {},
    d3dDatas : null,
    setD3dDatas : datas => {},
    miniSearch : {
        type : "Tous",
        state : "Tous",
        minSurface : null,
        maxSurface : null,
    },
    minSurface : null,
    searchResult : [],
    setSearchResult : settings => {},
    ficheProjet : null,
    setFicheProjet : fiche => {},
    ficheProjetRef : null,
    mapRef : null,
    block : "Vue générale",
    block : block => {},
    currentMqtLvl0 : "Vue générale",
    setCurrentMqtLvl0 : lvl0 => {},
    currentMqtLvl1 : "",
    setCurrentMqtLvl1 : lvl1 => {},
    selectedPoi : null,
    setSelectedPoi : selected => {},
    preloaded : null,
    setPreloaded : preloaded => {},
    imgsMemory : {},
    setImgsMemory : imgMemory => {},
    loadCustomProject : () => {},
    currentRotator : null,
    projects : null, 
    setProjects : projects => {},
    medias : null,
    setMedias : medias => {},
}); // Contexte pour la page active

export const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

export const noHeaderContext = React.createContext(false); // Utilisation du noHeader