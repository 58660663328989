// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du bandeau VB + cookies
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 30/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React from 'react';

// Imports contexts
import { domain } from './../../contexts';

// Bouton
const Credits = () => {
    const handleDivClick = () => {
        window.open('https://www.asylum.fr/district-3d/', '_blank');
    };
    return (
        <div id='creditsAsy' onClick={handleDivClick}>
            <div className='creditsTitle'>Powered by</div>
            <div className='creditsImage'>
                <img src={`/images/logo-asylum.png`} alt="Asylum Logo"></img>
            </div>
        </div>
    )

}

export default Credits;