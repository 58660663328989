// /////////////////////////////////////////////////////////////
// Fichier pour le preload des medias en vue d'une app offline
// /////////////////////////////////////////////////////////////
// Creation : 28/02/2023      -> CGR
// /////////////////////////////////////////////////////////////

import { useEffect, useContext } from "react";
import {navigationContext} from "./../../contexts";


const resolveImages = (d3d, block, resol, frames) => {

    return new Promise(function (resolve, reject) {
      if (frames) {
        const a = new Image ();
        a.src = `/d3d/${d3d}/3D/maquette/${block}/Aerien/jpg-${resol}/${frames[0]}.jpg`
        const b = new Image ();
        b.src = `/d3d/${d3d}/3D/maquette/${block}/Aerien/jpg-${resol}/${frames[1]}.jpg`
        const c = new Image ();
        c.src = `/d3d/${d3d}/3D/maquette/${block}/Aerien/jpg-${resol}/${frames[2]}.jpg`

        c.addEventListener('load', () => {
          setTimeout(() => {
            resolve([a, b, c])
          }, 40)
        });
      }
      else{resolve();}
    });

  };



const AppLoader = (d3d, datas, medias, preloaded, setPreloaded, loadedFrames, setLoadedFrames, setImgsMemory) => {


    // Chargement de l'ensemble des contenus cartes
    if (datas && datas.cartes && Object.keys(datas.cartes).length > 0) {
        Object.keys(datas.cartes).map((carte) => {
            fetch(`/d3d/${d3d}/2D/cartes/${carte}.json`, { mode: 'cors' })
            .catch(() => {})
        })
    }
    if (datas && datas.themes && Object.keys(datas.themes).length > 0) {
        Object.keys(datas.themes).map((theme) => {
            fetch(`/d3d/${d3d}/2D/themes/${theme}.json`, { mode: 'cors' })
            .catch(() => {})
        })
    }

    // Chargement de la maquette principale
    const fnR = (f, b, loadRes, toLoad) => {

        resolveImages(d3d, Object.keys(medias)[b], loadRes[toLoad], [
          (3*f).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false}), 
          (3*f+1).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false}), 
          (3*f+2).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false})
        ]).then((imgs) => {

            setImgsMemory(m => {
                const mCopie = {...m}
                if (Object.keys(mCopie).includes(Object.keys(medias)[b])) {
                    if (Object.keys(mCopie[Object.keys(medias)[b]]['Aerien']).includes(loadRes[toLoad].toString())) {
                        mCopie[Object.keys(medias)[b]]['Aerien'][loadRes[toLoad]] = [...mCopie[Object.keys(medias)[b]]['Aerien'][loadRes[toLoad]], ...imgs]
                    } else {
                        mCopie[Object.keys(medias)[b]]['Aerien'][loadRes[toLoad]] = [...imgs]
                    }
                } else {
                    mCopie[Object.keys(medias)[b]] = {'Aerien' : {'156' : [...imgs]}}

                }
                return mCopie
            })         
    
          f = f+1
    
          if (f >= 30) {
    
            f = 0;
            b = b + 1;

            if (Object.keys(medias)[b]) {
                fnR(f, b, loadRes, toLoad)
            } else {
                setPreloaded(loadRes[toLoad]);
                setLoadedFrames(90);
                // lancement d'un nouveau chargement
                toLoad = toLoad + 1;
                if (loadRes[toLoad]) {
                    b = 0;
                    f = 0;
                    fnR(f, b, loadRes, toLoad)
                }

            }
          } else {
            fnR(f, b, loadRes, toLoad)
          }
          
        })
    }

    if (medias) {

        const loadRes = [156, 450, 720];
        let toLoad = 0;
        const f = 0;    
        let b = null;
        if (Object.keys(medias).length) {
            b=0;
            fnR(f, b, loadRes, toLoad);
        }
    }

    // Chargement de l'ensemble des contenues des fiches projets
    fetch(`/d3d/${d3d}/projets/projets.json`, { mode: 'cors' })
    .then(response => response.json())
    .then(datas => {

        if (Object.keys(datas.projets).length) {

            let projetToLoad = 0;
            let projectFrame = 0;

            const loadProjectFrames = (fr, p, res) => {

                if (!datas.projets[Object.keys(datas.projets)[p]]["maquette"]) {
                    p = p + 1;
                    fr = 0;

                    if (Object.keys(datas.projets)[p]) {
                        loadProjectFrames(fr, p, 156);
                    }
                } else {
                    resolveImages(d3d, Object.keys(datas.projets)[p], res, [
                        (3*fr).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false}), 
                        (3*fr+1).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false}), 
                        (3*fr+2).toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false})
                    ]).then((imgs) => {

                        setImgsMemory(m => {
                            const mCopie = {...m}

                            if (Object.keys(mCopie).includes(Object.keys(datas.projets)[p])) {
                                if (Object.keys(mCopie[Object.keys(datas.projets)[p]]['Aerien']).includes(res.toString())) {
                                    mCopie[Object.keys(datas.projets)[p]]['Aerien'][res] = [...mCopie[Object.keys(datas.projets)[p]]['Aerien'][res], ...imgs]
                                } else {
                                    mCopie[Object.keys(datas.projets)[p]]['Aerien'][res] = [...imgs]
                                }
                            } else {
                                mCopie[Object.keys(datas.projets)[p]] = {'Aerien' : {'156' : [...imgs]}}
                            }

                            return mCopie
                        })    

                        fr = fr+1


                        if (fr >= 30) {
                            p = p + 1;
                            fr = 0;

                            if (Object.keys(datas.projets)[p]) {
                                loadProjectFrames(fr, p, 156);
                            }

                        } else {
                            loadProjectFrames(fr, p, 156);
                        }
                  })
                }
            }

            loadProjectFrames(projectFrame, projetToLoad, 156);


        }


            // Gestion de la galerie
            // if (datas.projets[p].images && datas.projets[p].images.length > 0) {
            //     resolveImages(datas.projets[p].images.map(i => `/d3d/${d3d}/projets/galerie/${i}`))
            //     .then((imgs) => {
            //         imgs.forEach((img) => {
            //             imageFragment.appendChild(img)
            //         });
            //         // document.querySelector("#root").appendChild(imageFragment)
            //     }, () => {})
            // }

            // Gestion des pdfs
            // if (datas.projets[p].pdfs && datas.projets[p].pdfs.length > 0) {
            //     resolveImages(datas.projets[p].pdfs.map(pdf => `/d3d/${d3d}/projets/pdfs/${pdf}`))
            //     .then((imgs) => {
            //         imgs.forEach((img) => {
            //             imageFragment.appendChild(img)
            //         });
            //         // document.querySelector("#root").appendChild(imageFragment)
            //     }, () => {})
            // }
            // Gestion des avants apres
            // if (datas.projets[p]["before-after"] && datas.projets[p]["before-after"].length > 0) {
            //     datas.projets[p]["before-after"].map(ba => {
            //             fetch(`/d3d/${msg.data.d3d}/projets/avant-apres/${ba.images[0]}`)
            //             .catch(() => {})
            //             fetch(`/d3d/${msg.data.d3d}/projets/avant-apres/${ba.images[1]}`)
            //             .catch(() => {})
            //     })
            // }
            // Gestion des maquettes 3D
            //    loadCustomProject(p, () => {})


            
        // })
        // })
    });

}


// const resolveImages = urlArray => {
//     const imagesPromiseArray = urlArray.map(url => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.onload = () => {
//           resolve(img);
//         };
//         img.onerror = () => {
//           resolve(null);
//         };
//         img.src = url;
//       });
//     });
  
//     return new Promise((resolve, reject) => {
//       Promise.all(imagesPromiseArray).then(images => {
//         resolve(images.filter(Boolean));
//       });
//     });
  
// };

// const resolveImages = urlArray => {
//     const createImage = url => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.onload = () => {
//           resolve(img);
//         };
//         img.onerror = () => {
//           resolve(null);
//         };
//         img.src = url;
//       });
//     };
//     return new Promise((resolve, reject) => {
//       const worker = new Worker('/workerLoader.js');
//       worker.postMessage(urlArray);
//       worker.addEventListener(
//         "message",
//         async function(event) {
//           const imagePromises = event.data.map(async url => {
//             if (url) {
//               return await createImage(url);
//             }
//           });
//           const imageElements = await Promise.all(imagePromises);
//           resolve(imageElements.filter(Boolean));
//         },
//         false
//       );
//     });
//   };

// const AppLoader = (d3d, programmeDatas) => {



//     // Chargement de l'ensemble des contenus cartes
//     if (programmeDatas && programmeDatas.cartes && Object.keys(programmeDatas.cartes).length > 0) {
//         Object.keys(programmeDatas.cartes).map((carte) => {
//             fetch(`/d3d/${d3d}/2D/cartes/${carte}.json`, { mode: 'cors' })
//             .catch(() => {})
//         })
//     }
//     if (programmeDatas && programmeDatas.themes && Object.keys(programmeDatas.themes).length > 0) {
//         Object.keys(programmeDatas.themes).map((theme) => {
//             fetch(`/d3d/${d3d}/2D/themes/${theme}.json`, { mode: 'cors' })
//             .catch(() => {})
//         })
//     }
//     fetch(`/d3d/${d3d}/2D/projets/projets.json`, { mode: 'cors' })
//     .catch(() => {})

//     // Chargement de l'ensemble des contenues des fiches projets
//     fetch(`/d3d/${d3d}/projets/projets.json`, { mode: 'cors' })
//     .then(response => response.json())
//     .then(datas => {
//         Object.keys(datas.projets).map(p => {
//             // Gestion des vignettes
//             fetch(`/d3d/${d3d}/projets/previews/${p}.jpg`)
//             .catch(() => {})

//             // Gestion de la galerie
//             if (datas.projets[p].images && datas.projets[p].images.length > 0) {
//                 resolveImages(datas.projets[p].images.map(i => `/d3d/${d3d}/projets/galerie/${i}`))
//                 .then((imgs) => {
//                     imgs.forEach((img) => {
//                         imageFragment.appendChild(img)
//                     });
//                     // document.querySelector("#root").appendChild(imageFragment)
//                 }, () => {})
//             }
//             // Gestion des pdfs
//             if (datas.projets[p].pdfs && datas.projets[p].pdfs.length > 0) {
//                 resolveImages(datas.projets[p].pdfs.map(pdf => `/d3d/${d3d}/projets/pdfs/${pdf}`))
//                 .then((imgs) => {
//                     imgs.forEach((img) => {
//                         imageFragment.appendChild(img)
//                     });
//                     // document.querySelector("#root").appendChild(imageFragment)
//                 }, () => {})
//             }
//             // Gestion des avants apres
//             // if (datas.projets[p]["before-after"] && datas.projets[p]["before-after"].length > 0) {
//             //     datas.projets[p]["before-after"].map(ba => {
//             //             fetch(`/d3d/${msg.data.d3d}/projets/avant-apres/${ba.images[0]}`)
//             //             .catch(() => {})
//             //             fetch(`/d3d/${msg.data.d3d}/projets/avant-apres/${ba.images[1]}`)
//             //             .catch(() => {})
//             //     })
//             // }
//             // Gestion des maquettes 3D
//             //    loadCustomProject(p, () => {})

//             resolveImages(Array.from({length : 90}, (_, i) => i).map(i => {
//                 const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
//                 return `/d3d/${d3d}/3D/maquette/${p}/Aerien/jpg-450/${strIndex}.jpg`
//             })).then((imgs) => {
//                 imgs.forEach((img) => {
//                     imageFragment.appendChild(img)
//                 });
//                 // document.querySelector("#root").appendChild(imageFragment)
//             }, () => {})
//             resolveImages(Array.from({length : 90}, (_, i) => i).map(i => {
//                 const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
//                 return `/d3d/${d3d}/3D/maquette/${p}/Aerien/jpg-720/${strIndex}.jpg`
//             })).then((imgs) => {
//                 imgs.forEach((img) => {
//                     imageFragment.appendChild(img)
//                 });
//                 // document.querySelector("#root").appendChild(imageFragment)
//             }, () => {})
    
//         // })
//         })
//     });
// }


// ///////////////////////////////////
// Export
export default AppLoader;



