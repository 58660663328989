// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la fiche projet des themes
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 26/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems

// Imports contexts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


// /////////////////////////////////////////////////////////////
// Components

const PdfViewer = ({url, setPdfViewer}) => {
  return <div id="pdfViewer">
    <div id="closeViewer" onClick={() => {setPdfViewer(null)}}>
      <FontAwesomeIcon icon = {faClose} size={"xl"}/>
    </div>
    <embed 
      src={`${url}#view=FitV&toolbar=0&navpanes=0&scrollbar=0`} 
      type='application/pdf'
    />
  </div>
}


// /////////////////////////////////////////////////////////////
// Export
  export default PdfViewer;
  