// /////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des elements 3D en surimpression des images pre-calcules
// /////////////////////////////////////////////////////////////////////////////////
// Creation : 21/04/2022  CGR
// /////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, { useState, useEffect, useContext, useRef } from 'react';

// Three
import * as THREE from 'three'
import { useThree } from "@react-three/fiber"

// Components
import PlayerToolTip from './playerToolTip/playerToolTip'
// import { domain, programmeContext, programmeLotsContext, programmeNameContext } from '../../contexts';
// import SvgIcon from '../svg/svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';



// ////////////////////////////////////////////////////////////////////////
// Moteur 3D
const ThreeJsPlayer = ({
    d3dDatas,
    programme,
    frame,
    block,
    floor,
    focale,
    setSurvol,
    puces,
    setPuces,
    setIndicators,
    medias = null,
    setBlock = undefined,
    filterOne = undefined,
    lots = undefined,
    alreadyOveredLot = undefined,
    setAlreadyOveredLot = undefined,
    zoom = 1,
    setExtActiv = () => { },
    navInfo = {
        current0: null,
        current1: null
    },
    searchResult = [],
    ficheProjetRef = null,
    ficheProjet,
    setFicheProjet = () => { },
    isMoving = false,
    projects = null
}) => {

    // Three
    const { scene, camera, gl } = useThree();
    const [zonesDisplay, setZonesDisplay] = useState('Grisés');
    // const [medias, setMedias] = useState(null); // donnees de la maquette3D
    const [zones, setZones] = useState([]); // Elements jsx pour affichage des zones
    const [hoveredZone, setHoveredZone] = useState(null); // zone en cours de survol
    const [hoveredZoneMemory, setHoveredZoneMemory] = useState(null); // tampon pour detection des changement de survol
    const [oldHoveredZone, setOldHoveredZone] = useState(null); // ancienne zone survolée pour permettre le retrait de l'opacité
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [preOvered, setPreOvered] = useState(alreadyOveredLot);
    const [cutEvent, setCutEvent] = useState(false); // Etat des ecoutes d'evenements -> true permet de desactiver le suivi souris
    const [pucesAreSettings, setPucesAreSettings] = useState(false);
    const zoneTouchMemory = useRef(null);
    const refMoving = useRef(false);

    console.log(d3dDatas)


    // Fonctions
    const clearScene = () => { // Fonction de nettoyage de la scene 3D -> retrait puces, zones
        // Del des zones
        let obj;
        for (let i = scene.children.length - 1; i >= 0; i--) {
            obj = scene.children[i];
            if (obj !== camera) {
                scene.remove(obj);
            }
        }
    }

    const updateCamera = () => { // Mise a jour de la camera au changement de frame

        if (medias != null) {

            const frameCamera = medias[block][floor].frames[frame];

            // maj cam
            if (frameCamera != null) {

                camera.setFocalLength(focale);
                camera.position.set(frameCamera[0], frameCamera[1], frameCamera[2]);
                camera.rotation.set(
                    (THREE.Math.degToRad(90 - frameCamera[3])),
                    (THREE.Math.degToRad(frameCamera[4])),
                    (THREE.Math.degToRad(-frameCamera[5]))
                )
                camera.updateMatrixWorld();
            }
        }
    }

    const buildZones = () => { // Fonction pour la construction des zones 3D

        if (block != undefined && medias != null) {

            setZones([]); // reset des zones 3D
            clearScene();

            if (Object.keys(medias[block]).includes(floor)) {

                // Partie zones
                const viewZones = medias[block][floor].zones;
                if (viewZones != undefined && Object.keys(viewZones).length !== 0) {

                    Object.keys(viewZones).map((lot, i) => {


                        // Si on filtre un lot alors on zappe tous les autres
                        if (filterOne != undefined && lot != filterOne) { return; }

                        let parent = new THREE.Object3D(); // nouvel objet 3D

                        for (let poly of viewZones[lot]) { // Parcours des traces de la zone
                            let zonePts = [];
                            for (let point of poly) { // Parcours des points de chaque trace
                                zonePts.push(new THREE.Vector2(parseFloat(point[0]), parseFloat(point[1])));
                            }
                            let zoneShape = new THREE.Shape(zonePts);
                            let geometry = new THREE.ExtrudeGeometry(zoneShape, { depth: 0, bevelEnabled: false })

                            // const theZone = lots.lots.find(x => x.name === lot);
                            if (block !== "Vue générale") {
                                const color = new THREE.Color(d3dDatas.default.color).convertSRGBToLinear();
                                const mtl = new THREE.MeshBasicMaterial({
                                    color: color,
                                    opacity: filterOne != undefined ? 0.8 : 0.6,
                                    transparent: true
                                })
                                let object = new THREE.Mesh(geometry, mtl);
                                object.position.z = parseFloat(poly[0][2]);
                                parent.add(object); // ajout du trace a l'objet
                                setZones(old => [...old, object]); // ajout en memoire des zones
                            } else {
                                if (lot === "P_VOILE") {
                                    const color = new THREE.Color(d3dDatas.maquette.voile.color).convertSRGBToLinear();
                                    const mtl = new THREE.MeshBasicMaterial({
                                        color: color,
                                        opacity: d3dDatas.maquette.voile.opacity,
                                        transparent: true
                                    })
                                    let object = new THREE.Mesh(geometry, mtl);
                                    object.position.z = parseFloat(poly[0][2]);
                                    parent.add(object); // ajout du trace a l'objet
                                    setZones(old => [...old, object]); // ajout en memoire des zones
                                } else if (lot.includes('CONTOUR')) {
                                    const color = new THREE.Color(d3dDatas.default.contourcolor).convertSRGBToLinear();
                                    const mtl = new THREE.MeshBasicMaterial({
                                        color: color,
                                        opacity: 1,
                                        transparent: true
                                    })
                                    let object = new THREE.Mesh(geometry, mtl);
                                    object.position.z = parseFloat(poly[0][2]);
                                    parent.add(object); // ajout du trace a l'objet
                                    setZones(old => [...old, object]); // ajout en memoire des zones
                                } else {
                                    const color = new THREE.Color(d3dDatas.default.color).convertSRGBToLinear();
                                    const mtl = new THREE.MeshBasicMaterial({
                                        color: color,
                                        opacity: filterOne != undefined ? 0.5 : 0.0,
                                        transparent: true
                                    })
                                    let object = new THREE.Mesh(geometry, mtl);
                                    object.position.z = parseFloat(poly[0][2]);
                                    parent.add(object); // ajout du trace a l'objet
                                    setZones(old => [...old, object]); // ajout en memoire des zones
                                }
                            }
                        }
                        parent.zoneId = lot;
                        scene.add(parent); // ajout du trace dans la 3D
                    })

                } else {
                    setZones([]);
                    setHoveredZone(null);
                    setSurvol(null);
                }
            }
        }
    }

    const setFicheProjetOnClick = (name) => {

        setFicheProjet(name)
        if (ficheProjetRef.current) {
            ficheProjetRef.current.className = "";
        }

    }

    const buildPuces = () => { // Mise en place des position de puce au changement de frame

        setPuces([]);

        if (medias) {

            const viewPuces = medias[block][floor].puces;

            if (viewPuces != undefined) {

                Object.keys(viewPuces).filter(puce =>
                    searchResult.includes(puce)
                ).map(puce => {

                    const pos = new THREE.Vector3();
                    pos.x = viewPuces[puce].position[0];
                    pos.y = viewPuces[puce].position[1];
                    pos.z = viewPuces[puce].position[2];

                    pos.project(camera);
                    const pagePosition = convertWorldToHtmlRelativeCoordinates(pos.x, pos.y);

                    const projectsNames = Object.entries(projects).map(k => k[1].name)
                    const puceProject = projectsNames.findIndex(p => p === puce)
                    let color = d3dDatas.default.color
                    
                    if (puceProject !== -1 && d3dDatas?.maquette?.puces?.colorType === "typologies") {
                        color = d3dDatas.maquette.puces.colors[projects[Object.keys(projects)[puceProject]].typologie[0]]
                    }

                    setPuces(old => [...old,
                    <div
                        key={puce}
                        className="puce"
                        onClick={() => { setFicheProjetOnClick(puce); }}
                        onTouchEnd={() => { setFicheProjetOnClick(puce); }}
                        style={{ 'left': `${pagePosition.x}px`, 'top': `${pagePosition.y}px` }}
                    >
                        <div className={`puce-container ${ficheProjet === puce && 'current'}`}>
                            <div className="puce-base" style = {{backgroundColor : color}}>
                                <div className="puce-center">
                                    <FontAwesomeIcon icon={faAdd} color={'#282828'} />
                                </div>
                            </div>
                        </div>
                        <div className={`puce-text ${block !== "Vue générale" && 'noToolTip'}`} style = {{backgroundColor : color}}>
                            {puce}
                        </div>
                    </div>
                    ]); // ajout en memoire des zones
                })
            }
        }
    }


    const sortSplineByZones = (zones) => {
        let arrayZones = [];
        let arrayContour = [];

        zones.forEach((zone, i) => {
            if (zone.parent.zoneId.includes("CONTOUR") || zone.parent.zoneId === "P_VOILE") {
                arrayContour.push(zone);
            } else if (i === 0 || zone.parent.zoneId !== zones[i - 1].parent.zoneId) {
                arrayZones.push([]);
                arrayZones[arrayZones.length - 1].push(zone);
            } else {
                arrayZones[arrayZones.length - 1].push(zone);
            }
        });

        return [arrayZones, arrayContour];

    }

    const buildIndicators = () => {

        setIndicators([]);

        const splineZone = sortSplineByZones(zones)[0];
        const splineContour = sortSplineByZones(zones)[1];

        if (block === "Vue générale") {
            for (const zone of zones) {


                const name = zone.parent.zoneId;
                if (!name.includes("CONTOUR") && name !== "P_VOILE") {

                    const geometry = zone.geometry;
                    const middle = new THREE.Vector3();

                    geometry.computeBoundingBox();
                    camera.updateMatrixWorld();

                    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
                    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
                    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;

                    zone.localToWorld(middle);
                    middle.project(camera);
                    const position2D = convertWorldToHtmlRelativeCoordinates(middle.x, middle.y);


                    const numValue = Object.keys(medias[name].Aerien.puces).filter(z => searchResult.includes(z)).length

                    // Ajout de la puce de resultat
                    if (numValue) {
                        setIndicators(indicators =>
                            [...indicators, <div
                                id=''
                                className="playerProjetsCountInfo"
                                style={{ left: position2D.x, top: position2D.y }}
                            >
                                {numValue}
                            </div>]
                        );
                    }
                }
            }
        }
    }

    const setToolTipPosition = (zone) => { // Fonction pour placer le toolTip de lot a la position de la zone

        const middle = new THREE.Vector3();

        const geo = zone.children[0].geometry;
        geo.computeBoundingBox();
        camera.updateMatrixWorld();

        middle.x = (geo.boundingBox.max.x + geo.boundingBox.min.x) / 2;
        middle.y = (geo.boundingBox.max.y + geo.boundingBox.min.y) / 2;
        middle.z = (geo.boundingBox.max.z + geo.boundingBox.min.z) / 2;

        zone.children[0].localToWorld(middle);
        if (middle.z == 0) {
            setTimeout(() => {
                setToolTipPosition(zone);
            }, 10)
            return;
        }

        middle.project(camera);
        const position2D = convertWorldToHtmlRelativeCoordinates(middle.x, middle.y);

        if (block === "Vue générale") {
            // Maj contenu React 
            setSurvol(<PlayerToolTip
                programme={programme}
                name={d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId}
                type="T2"
                surface={42.12}
                x={position2D.x}
                y={position2D.y}
                setBlock={setBlock}
                plusAction={
                    navInfo.current0 ? () => navInfo.setCurrentLvl1(d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId) : () => { }
                }
            />);
        } else {
            setSurvol(<PlayerToolTip
                programme={programme}
                name={d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId}
                type="textOnly"
                surface={42.12}
                x={position2D.x + 20}
                y={position2D.y - 30}
            />);
        }
    };

    const setZoneOpacity = (zone, val) => { // Fonction pour la modification de l'opacite d'une zone 3D
        for (let i in zone.children) {
            zone.children[i].material.opacity = val;
        }
    };

    const convertWorldToHtmlRelativeCoordinates = (vx, vy) => { // Fonction pour obtenir la position 2D depuis une coordonée 3D de la scene
        // Calcul
        const playerDom = document.getElementById("player-3D");
        const widthHalf = playerDom.offsetWidth / 2;
        const heightHalf = playerDom.offsetHeight / 2;
        let x = (vx * widthHalf) + widthHalf;
        let y = -(vy * heightHalf) + heightHalf - 50;
        // Conversion + envoi
        x = parseInt(x); y = parseInt(y);
        return { x: x, y: y };
    };

    // Events
    const handleMouseMove = (event) => { // Detection des mouvements de souris pour la gestion des survols de zone

        // On coupe les survol dans le cas ou un lot specifique soit filtre
        if (event.target.id === 'lotHover' || event.target.id === 'lotHover-link' || filterOne != undefined) {
            return;
        }

        const playerDom = document.getElementById("player-3D");

        const mouseX = (event.offsetX / playerDom.offsetWidth) * 2 - 1;
        const mouseY = - (event.offsetY / playerDom.offsetHeight) * 2 + 1;

        const mouse = new THREE.Vector2(mouseX, mouseY); // Gestionnaire de curseur pour la scene 3D
        const projector = new THREE.Raycaster(); // Creation du traceur
        projector.setFromCamera(mouse, camera);

        const intersects = projector.intersectObjects(zones);

        if (intersects.length > 0) {
            // On retire d'abord le hover

            if (!intersects[0].object.parent.zoneId.includes("CONTOUR") && intersects[0].object.parent.zoneId !== "P_VOILE") {
                if (block === "Vue générale") {
                    let zone = intersects[0].object.parent;
                    setToolTipPosition(zone);
                    setZoneOpacity(zone, 0.5);
                    zoneTouchMemory.current = zone.zoneId
                    setHoveredZone(zone);
                } else {
                    let zone = intersects[0].object.parent;
                    setZoneOpacity(zone, 0.8);
                    setHoveredZone(zone);
                    setToolTipPosition(zone);
                }
            }
        } else {
            zoneTouchMemory.current = null;
            setOldHoveredZone(hoveredZone);
            setHoveredZone(null);
            setSurvol(null);
        }

    }

    const handleTouchStart = (event) => { // premier contact

        event.preventDefault();

        // On coupe les survol dans le cas ou un lot specifique soit filtre
        if (event.target.id === 'lotHover' || event.target.id === 'lotHover-link' || filterOne != undefined) {
            return;
        }

        const playerDom = document.getElementById("player-3D");

        var rect = event.target.getBoundingClientRect();

        var x;
        var y;

        if (event.targetTouches) {
            var x = event.targetTouches[0].pageX - rect.left;
            var y = event.targetTouches[0].pageY - rect.top;
        } else {
            var x = event.offsetX;
            var y = event.offsetY;
        }

        const mouseX = (x / playerDom.offsetWidth) * 2 - 1;
        const mouseY = - (y / playerDom.offsetHeight) * 2 + 1;

        const mouse = new THREE.Vector2(mouseX, mouseY); // Gestionnaire de curseur pour la scene 3D
        const projector = new THREE.Raycaster(); // Creation du traceur
        projector.setFromCamera(mouse, camera);

        const intersects = projector.intersectObjects(zones);

        if (event.targetTouches) {
            if (intersects.length > 0) {
                // On retire d'abord le hover

                if (!intersects[0].object.parent.zoneId.includes("CONTOUR") && intersects[0].object.parent.zoneId !== "P_VOILE") {
                    if (block === "Vue générale") {

                        let zone = intersects[0].object.parent;

                        if (zoneTouchMemory.current) {
                            if (zoneTouchMemory.current === zone.zoneId) {
                                setZoneOpacity(zone, 0);
                                setHoveredZone(null);
                                zoneTouchMemory.current = null;
                                setSurvol(null);
                                navInfo.setCurrentLvl1(d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId);
                                setBlock(zone.zoneId);
                            } else {
                                setToolTipPosition(zone);
                                setZoneOpacity(zone, 0.5);
                                setHoveredZone(zone);
                                zoneTouchMemory.current = zone.zoneId;
                            }
                        } else {
                            setToolTipPosition(zone);
                            setZoneOpacity(zone, 0.5);
                            setHoveredZone(zone);
                            zoneTouchMemory.current = zone.zoneId;
                        }
                    } else {

                        let zone = intersects[0].object.parent;

                        if (zoneTouchMemory.current) {
                            if (zoneTouchMemory.current === zone.zoneId) {
                                zoneTouchMemory.current = null;
                                navInfo.setCurrentLvl1(d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId);
                                setBlock(zone.zoneId);
                            } else {
                                setToolTipPosition(zone);
                                setZoneOpacity(zone, 0.5);
                                setHoveredZone(zone);
                                zoneTouchMemory.current = zone.zoneId;
                            }
                        } else {
                            setToolTipPosition(zone);
                            setZoneOpacity(zone, 0.5);
                            setHoveredZone(zone);
                            zoneTouchMemory.current = zone.zoneId;
                        }

                    }
                }
            } else {
                
                setOldHoveredZone(hoveredZone);
                setHoveredZone(null);
                setSurvol(null);
                zoneTouchMemory.current = null;
            }
        }

    }

    const handleMouseUp = (event) => {

        event.preventDefault();

        if (!event.targetTouches) {
            if (!refMoving.current) {
                const playerDom = document.getElementById("player-3D");

                const mouseX = (event.offsetX / playerDom.offsetWidth) * 2 - 1;
                const mouseY = - (event.offsetY / playerDom.offsetHeight) * 2 + 1;

                const mouse = new THREE.Vector2(mouseX, mouseY); // Gestionnaire de curseur pour la scene 3D
                const projector = new THREE.Raycaster(); // Creation du traceur
                projector.setFromCamera(mouse, camera);

                const intersects = projector.intersectObjects(zones);

                if (block !== "Vue générale") {

                    if (intersects.length > 0) {
                        // On retire d'abord le hover
                        let zone = intersects[0].object.parent;
                        navInfo.setCurrentLvl1(d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId);
                        setBlock(zone.zoneId);
                    }
                } else {
                    if (intersects.length > 0) {
                        if (!intersects[0].object.parent.zoneId.includes("CONTOUR") && intersects[0].object.parent.zoneId !== "P_VOILE") {
                            let zone = intersects[0].object.parent;
                            navInfo.setCurrentLvl1(d3dDatas["sous-complexes"] && d3dDatas["sous-complexes"][zone.zoneId] ? d3dDatas["sous-complexes"][zone.zoneId] : zone.zoneId);
                            setBlock(zone.zoneId);
                        }
                    }

                }
            }
        }
    }

    // Effects
    useEffect(() => {
        refMoving.current = isMoving
        if (isMoving) {
            zoneTouchMemory.current = null;
        }
    }, [isMoving])

    useEffect(() => { // recuperations des infos du Json

        if (programme !== undefined) {
            // Gestion de la camera
            camera.rotation.order = 'ZYX'; // correction de l'alignement des axes pour coller avec max
            camera.far = 1000000; // gestion du clipping pour afficher les elements lointains
        }

    }, [programme])

    useEffect(() => { // Gestion du preOver -> survol issu de parametre (exemple via url ou clic moteur recherche)
        setPreOvered(alreadyOveredLot);
    }, [alreadyOveredLot])

    useEffect(() => { // Gestion du preOver -> ajout d'un timer pour couper automatiquement le preOver apres un certain temps
        let interval;

        if (preOvered != undefined) {
            setCutEvent(true);
            interval = setTimeout(() => {
                setPreOvered(undefined);
            }, 3000)
        }
        return (() => { clearInterval(interval) })

    }, [preOvered])

    useEffect(() => { // Mise a jour des zones pour chaque etage
        buildZones();
    }, [medias, block, floor, filterOne])

    useEffect(() => { // Mise a jour de la camera

        // recuperation de la frame
        updateCamera();
        buildPuces();

    }, [medias, block, floor, frame])

    useEffect(() => {
        if (zones.length > 0) {
            buildIndicators();
        }
    }, [zones, medias, block, floor, frame, searchResult])

    useEffect(() => { // Mise en place des puces

        if (isFirstOpen == true) { // On laisse un petit delais pour que la scene 3D se charge totalement a la premiere ouverture
            setTimeout(() => {
                setIsFirstOpen(false);
            }, 1000)
            return;
        }

    }, [isFirstOpen, medias, block, floor, frame])

    useEffect(() => { // Mise en place des events des que les zones sont en place

        // Creation des event
        document.getElementById("player-3D").removeEventListener("mousemove", handleMouseMove);
        document.getElementById("player-3D").removeEventListener("touchstart", handleTouchStart);
        document.getElementById("player-3D").removeEventListener("mousedown", handleTouchStart);

        if (zones.length != 0) {

            if (cutEvent == false) {
                document.getElementById("player-3D").addEventListener("mousemove", handleMouseMove);
                document.getElementById("player-3D").addEventListener("touchstart", handleTouchStart);
                // document.getElementById("player-3D").addEventListener("mousedown", handleTouchStart);
                document.getElementById("player-3D").addEventListener("mousedown", (handleTouchStart));
                document.getElementById("player-3D").addEventListener("mouseup", handleMouseUp);
            }

            // Gestion du cas de preOver
            if (preOvered != undefined) {
                zones.map((zone) => {
                    if (zone.parent.zoneId == preOvered) {
                        setToolTipPosition(zone.parent);
                        setZoneOpacity(zone.parent, 0.5);
                        setHoveredZone(zone.parent);
                    }
                })
            } else {
                setOldHoveredZone(hoveredZone);
                setHoveredZone(null);
                setCutEvent(false);
                setSurvol(null);
            }
        }

        return () => {
            const played3D = document.getElementById("player-3D");
            if (played3D != undefined) {
                document.getElementById("player-3D").removeEventListener("mousemove", handleMouseMove);
                document.getElementById("player-3D").removeEventListener("touchstart", handleTouchStart);
                document.getElementById("player-3D").removeEventListener("mousedown", handleTouchStart);

            }
        }


    }, [zones, preOvered, cutEvent, isMoving])

    useEffect(() => { // Gestion du comportement au survol d'une zone
        if (hoveredZone != hoveredZoneMemory) {
            setOldHoveredZone(hoveredZoneMemory);
            setHoveredZoneMemory(hoveredZone);
        }
    }, [hoveredZone, hoveredZoneMemory])

    useEffect(() => { // Action pour le retrait de la surbrillance de l'ancienne zone survolée
        if (oldHoveredZone != null) {
            if (block === "Vue générale") {
                setZoneOpacity(oldHoveredZone, 0.0)
            } else {
                setZoneOpacity(oldHoveredZone, 0.6)
            }

        }
    }, [oldHoveredZone])


    useEffect(() => { // Mise en place des puces au changement de vue

        setPuces([])
        if (medias) {
            const viewPuces = medias[block][floor].puces;
            if (viewPuces != undefined) {
                Object.keys(viewPuces).filter(puce =>
                    searchResult.includes(puce)
                ).map(puce => {
                    const pos = new THREE.Vector3();
                    pos.x = viewPuces[puce].position[0];
                    pos.y = viewPuces[puce].position[1];
                    pos.z = viewPuces[puce].position[2];
                    pos.project(camera);
                    const pagePosition = convertWorldToHtmlRelativeCoordinates(pos.x, pos.y);


                    const projectsNames = Object.entries(projects).map(k => k[1].name)
                    const puceProject = projectsNames.findIndex(p => p === puce)
                    let color = d3dDatas.default.color
                    
                    if (puceProject !== -1 && d3dDatas?.maquette?.puces?.colorType === "typologies") {
                        color = d3dDatas.maquette.puces.colors[projects[Object.keys(projects)[puceProject]].typologie[0]]
                    }

                    setPuces(old => [...old,
                    <div
                        key={puce}
                        className="puce"
                        onClick={() => { setFicheProjetOnClick(puce); }}
                        onTouchEnd={() => { setFicheProjetOnClick(puce); }}
                        style={{ 'left': `${pagePosition.x}px`, 'top': `${pagePosition.y}px` }}
                    >
                        <div className={`puce-container ${ficheProjet === puce && 'current'}`}>
                            <div className="puce-base" style = {{backgroundColor : color}}>
                                <div className="puce-center">
                                    <FontAwesomeIcon icon={faAdd} color={'#282828'} />
                                </div>
                            </div>
                        </div>
                        <div className={`puce-text ${block !== "Vue générale" && 'noToolTip'}`} style = {{backgroundColor : color}}>
                            {puce}
                        </div>
                    </div>
                    ]); // ajout en memoire des zones
                })
            }
        }
    }, [medias, block, floor, searchResult, ficheProjet])


    // Render
    return (
        <>
            <ambientLight intensity={1} />
            <directionalLight position={[-2, 5, 2]} intensity={1} />
        </>
    )
}


// ////////////////////////////////////////////////////////////////////////
// Export
export default ThreeJsPlayer;
