// /////////////////////////////////////////////////////////////
// Fichier pour la gestion du menu principal de D3D
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 12/09/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React from 'react';
// Imports components
import FullScreen from '../../fullScreen/fullScreen';

const HeaderBottom = () => {
    return (
      <div id = 'headerBottom'>
            <FullScreen />
        </div>
    )
  }

export default HeaderBottom;