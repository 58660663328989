// /////////////////////////////////////////////////////////////
// Composant pour rediriger vers une page externe
// /////////////////////////////////////////////////////////////
// Creation : 16/09/2022      -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';



// ///////////////////////////////////
// Component

const Redirect= ({to}) => {
    window.location.replace(to);
}

// ///////////////////////////////////
// Export
export default Redirect;
