import React from 'react';
import svgHelper from './svgHelper';

const SvgIcon = ({icon = "", fill = false}) => {

    return (
        svgHelper[icon]
    )    
}

export default SvgIcon;