// Fonction pour la recuperation de l'ensemble des donnes d'un projet D3D. Regroupe tous les appels au back.

export const getD3DDatas = async (d3d) => {

    try {
        const response = await fetch(`/d3d/${d3d}/programme.json`, { mode: 'cors' })
        const datas = await response.json()
        return datas
    }
    catch (e) {
        return null
    }
}

export const getD3DProjects = async (d3d) => {

    const response = await fetch(`/d3d/${d3d}/projets/projets.json`, { mode: 'cors' })
    const projects = await response.json()
    return projects.projets
}

export const getD3DMedias = async (d3d) => {
    try {
        const response = await fetch(`/d3d/${d3d}/3D/media.json`, {mode: 'cors'})
        const medias = await response.json()
        return medias
    } catch (e) {
        return null
    }
}


export const getD3DManifest = async (d3d) => {

    try {
        await fetch(`/d3d/${d3d}/manifest.json`, { mode: 'cors' })
        const link = document.createElement("link")
        link.rel = "manifest"
        link.setAttribute('href', `/d3d/${d3d}/manifest.json`)
        document.head.appendChild(link)
    }
    catch (e) {
        return null
    }
}

