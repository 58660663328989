// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page d'accueil
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR  -> le 19/10/2022
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Imports

// Imports systems
import React, { useContext, useEffect } from 'react';
import { navigationContext, parentContext } from '../../contexts';
import { useParams, Link, useNavigate } from 'react-router-dom';



// /////////////////////////////////////////////////////////////
// Component
const Home = () => {

    const {d3d, d3dDatas, setD3d} = useContext(navigationContext);
    const nav = useNavigate();
    const {setParent} = useContext(parentContext);
    

    // Render
    return (
        d3d === "only-lyon" ? 
            <div id="home">
                <img src = {`/d3d/${d3d}/home/${d3dDatas.pages.home.img}`}/>
                <div id="home-containernavigation">
                <div id = "homeNavigation">
                    <Link to = "/gerland/3D" className='homeNavigationBtn' onClick={() => {setParent("only-lyon"); }}>GERLAND</Link>
                    <Link to = "/carre-soie/3D" className='homeNavigationBtn' onClick={() => {setParent("only-lyon");}}>CARRÉ DE SOIE</Link>
                    <Link to = "/part-dieu/3D" className='homeNavigationBtn' onClick={() => {setParent("only-lyon");}}>PART-DIEU</Link>
                    <Link to = "/confluence/3D" className='homeNavigationBtn' onClick={() => {setParent("only-lyon");}}>CONFLUENCE</Link>
                </div>
                </div>
            </div>
        : 
        <div id="home">
            <img src = {`/d3d/${d3d}/home/${d3dDatas.pages.home.img}`}/>
            <Link id = "startBtn" to = {`/${d3d}/3D`}>Découvrir</Link>
        </div>
    )
}


  
// /////////////////////////////////////////////////////////////
// Export
export default Home;


//{datas => datas &&

// datas.d3d === "only-lyon" ? 
//     <div id="home">
//         <img src = {`/d3d/${datas.d3d}/home/${datas.d3dDatas.pages.home.img}`}/>
//         <div id = "homeNavigation">
//             <Link to = "/gerland/3D" className='homeNavigationBtn' onClick={() => {datas.setD3d("gerland")}}>GERLAND</Link>
//             <Link to = "/carre-soie/3D" className='homeNavigationBtn' onClick={() => {datas.setD3d("carre-soie")}}>CARRÉ DE SOIE</Link>
//             <Link to = "/part-dieu/3D" className='homeNavigationBtn' onClick={() => {datas.setD3d("part-dieu")}}>PART-DIEU</Link>
//             <Link to = "/confluence/3D" className='homeNavigationBtn' onClick={() => {datas.setD3d("confluence")}}>CONFLUENCE</Link>
//         </div>
//     </div>
// : 
// <div id="home">
//     <img src = {`/d3d/${datas.d3d}/home/${datas.d3dDatas.pages.home.img}`}/>
//     <h1>
//         { datas.d3dDatas.pages.home.logo !== "" &&
//             <img src = {`/d3d/${datas.d3d}/home/${datas.d3dDatas.pages.home.logo}`}/>
//         }
//     </h1>
// </div>
// }