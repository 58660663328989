import React, { useState } from 'react';
import SvgIcon from '../svg/svg';

const PlayerRotate = ({ frames, setFrame, rotation, setRotation }) => {
    const [isLeftArrowClicked, setIsLeftArrowClicked] = useState(false);
    const [isRightArrowClicked, setIsRightArrowClicked] = useState(false);

    const handleLeftArrowClick = () => {
        const newRotation = (rotation + 4 + 360) % 360;
        setRotation(newRotation);
        setFrame(Math.floor(newRotation * frames / 360));
        setIsLeftArrowClicked(true);
        setTimeout(() => setIsLeftArrowClicked(false), 100);
    };

    const handleRightArrowClick = () => {
        const newRotation = (rotation - 4 + 360) % 360;
        setRotation(newRotation);
        setFrame(Math.floor(newRotation * frames / 360));
        setIsRightArrowClicked(true);
        setTimeout(() => setIsRightArrowClicked(false), 100);
    };

    return (
        <div id='playerRotate'>
            <div className={`playerArrow ${isLeftArrowClicked ? 'clicked' : ''}`} id='playerRotateLeftArrow' onClick={handleLeftArrowClick}>
                <SvgIcon icon={"picto-gauche"} />
            </div>
            <div id='playerRotateImg'>
                <SvgIcon icon={"picto-maquette"} />
            </div>
            <div className={`playerArrow ${isRightArrowClicked ? 'clicked' : ''}`} id='playerRotateRightArrow' onClick={handleRightArrowClick}>
                <SvgIcon icon={"picto-droite"} />
            </div>
        </div>
    );
}

export default PlayerRotate;